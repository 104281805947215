import "./App.css";
import "./assests/styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import RoutesComponent from "./routes";
import { BrowserRouter, withRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter basename="/">
          <RoutesComponent />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
};

export default withRouter(App);
