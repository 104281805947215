import React, { useEffect, useState } from "react";
import { Button, Carousel, Col, Container, Image, Row } from "react-bootstrap";
import HeroComponent from "../components/Hero";
import Aws from "../assests/images/aws.png";
import Cloudera from "../assests/images/cloudera.png";
import Googledev from "../assests/images/googledev.png";
import Sap from "../assests/images/sap.png";
import DigitalOcean from "../assests/images/digitalocean.png";
import Proddev from "../assests/images/prod-dev.png";
import Ourtech from "../assests/images/our-tech.png";
import Slider from "react-slick";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import homeSliderImage1 from "../assests/images/homeSliderImage1.png";
import homeSliderImage2 from "../assests/images/homeSliderImage2.png";
import homeSliderImage3 from "../assests/images/homeSliderImage3.png";
import homeSliderImage4 from "../assests/images/homeSliderImage4.png";
import homeSliderImage5 from "../assests/images/homeSliderImage5.png";
import homeSliderImage6 from "../assests/images/homeSliderImage6.png";
import homeSliderImage7 from "../assests/images/homeSliderImage7.png";
import { submitContactForm, uploadFile } from "../services";
import CustomToast from "../components/Toast";
import TBLogo from "../assests/images/TBLogo.png";
import AppFutura from "../assests/images/appfutura-badge.jpg";
import HelmetComponent from "../components/Helmet";
import SEO from "../components/SEO";
import { useHistory } from 'react-router-dom';

function Home() {
  const history = useHistory();
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let features = [
    {
      icon: "150+",
      name: "Projects",
      marginTop: false,
    },
    {
      icon: "70+",
      name: "Mobile App",
      marginTop: true,
    },
    {
      icon: "40+",
      name: "Blockchain",
      marginTop: false,
    },
    {
      icon: "50+",
      name: "Happy Clients",
      marginTop: true,
    },
    {
      icon: "70+",
      name: "Expert Teams",
      marginTop: false,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 648,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: homeSliderImage1,
      name: "Noah William",
      id: 1,
      para: "We would like to express our satisfaction in the cooperation regarding the development of our Poker and Rummy Games applications. George and the development team did a very professional job. We are satisfied with the solutions given to us and with the communication flow through the project.",
    },
    {
      icon: homeSliderImage2,
      name: "Joyce Barret",
      id: 2,
      para: "Digital Crew has delivered great results on time. We have worked with them for developing Sports Betting software. All through the working tenure, we found their team energetic and highly professional in working. Looking forward to working with them again",
    },
    {
      icon: homeSliderImage3,
      name: "Richard Samuel",
      id: 3,
      para: "We would like to recommend the Digital Crew development team. We have got solutions from them in iGaming; They have capabilities to handle multiple projects in Card Gaming, Fantasy Sports, Sports Book and got the games delivered to our company on time. We look forward to working with them in future projects",
    },
    {
      icon: homeSliderImage4,
      name: "Tim Larson",
      id: 4,
      para: "Job well done and I hope we can continue to grow together. Thank you for the services your team has provided. Your team has been a pleasure to work with, professional and timely.",
    },
    {
      icon: homeSliderImage5,
      name: "Kyle James",
      id: 4,
      para: 'The Digital Crew is always available, extremely knowledgeable, and have a tremendous "can-do" attitude. We have worked on a Sports Book with them and they provided the best solutions.',
    },
    {
      icon: homeSliderImage6,
      name: "Benjamin Liam",
      id: 4,
      para: "I would like to thank Digital Crew and George for all the efforts and suggestions that assisted us in providing Blockchain solutions to us in the area of web, technology.",
    },
    {
      icon: homeSliderImage7,
      name: "Oli John",
      id: 4,
      para: "I worked with George and team Digital Crew for several years on various projects. Unflinchingly reliable, professional and proactive from a technology and security standpoint.",
    },
  ];

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
      history.push("/thankyou-page");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };

  return (
    <>
    
      <SEO
        title="Best Game Development Company"
        description="Digital Crew is the best game development company in India, USA with over 8 years of expertise in Rummy, Poker, Ludo, Casion games, and a wide range of other gaming and IT solutions"
        url="https://digitalcrew.co.in/"
        keywords="cloud services, ai software development services, cloud computing, google cloud computing,full cycle product development"
      />
      {/* <HelmetComponent
        title={
          "Digital Crew - Card games, Sports Betting, Blockchain, Metaverse"
        }
      /> */}
      <HeroComponent
        bigText={
          "<span className='redQuotes'>“</span>Industries We Serve<span className='redQuotes'>”</span>"
        }
        smallText={
          "We facilitate companies to build their ideas into<br /> a successful growth story."
        }
        buttonText={"Talk with the Experts"}
        page={"home"}
      ></HeroComponent>
      <div className="home-section2">
        <div className="section2-upper" style={{marginTop:"30px"}}>
          <p className="midText">Innovation is our passion:</p>
          <p className="midSmallText">
            The highly dedicated team of the Digital crew believes in
            innovation. We are a team of fervid folks driven by one relentless
            pursuit- to craft innovative solutions and deliver incomparable
            results. We dream, architect, design and develop.
          </p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    lg={2}
                    md={3}
                    sm={3}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles uneven-columns margin-top-style"
                        : "column-styles"
                    }
                  >
                    <div className="white-circle">
                      <p className="number">{item.icon}</p>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section2-lower">
          <Fade bottom>
            <div className="mid-title">
              <p>Who we are working with?</p>
            </div>
          </Fade>
          <div className="sponsers">
            <Image src={Aws} alt="software development Company" width="100%" height="100%"/>
            <Image src={DigitalOcean} alt="software development Services" width="100%" height="100%"/>
            <Image src={Sap} alt="app development" width="100%" height="100%"/>
            <Image src={Cloudera} alt="mobile app development" width="100%" height="100%"/>
            <Image src={Googledev} alt="mobile app development company" width="100%" height="100%" />
            <a
              href="https://techbehemoths.com/company/digital-crew-2 "
              target="_blank"
            >
              <Image src={TBLogo} alt="mobile app development company" width="100%" height="100%" />
            </a>
            <a
              href="https://www.appfutura.com/companies/digital-crew"
              target="_blank"
            >
              <Image
                src={AppFutura}
                style={{ width: "120px", height:"100%" }}
                alt="mobile app development company"
              />
            </a>
            <a
              href="https://clutch.co/profile/digitalcrew#summary "
              target="_blank"
            >
              <Image
                style={{ backgroundColor: "#17313b", width:"100%", height:"100%", padding:"5px" }}
                src={
                  "data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='87.861' height='25' xml:space='preserve'%3E%3Cpath fill='%23FFF' d='M22.861 0h4v25h-4zM40.861 17.025c0 3.826-3.217 4.131-4.174 4.131-2.391 0-2.826-2.238-2.826-3.588V8h-4v9.548c0 2.37.744 4.326 2.048 5.63 1.152 1.153 2.878 1.783 4.748 1.783 1.326 0 3.204-.413 4.204-1.326V25h4V8h-4v9.025zM52.861 2h-4v6h-3v4h3v13h4V12h3V8h-3zM68.458 19.917c-.871.783-2.021 1.217-3.283 1.217-2.782 0-4.825-2.043-4.825-4.848s1.978-4.762 4.825-4.762c1.24 0 2.412.413 3.305 1.196l.607.522 2.697-2.696-.675-.609C69.522 8.504 67.415 7.7 65.174 7.7c-5 0-8.631 3.608-8.631 8.565 0 4.936 3.718 8.673 8.631 8.673 2.283 0 4.412-.804 5.979-2.26l.652-.609-2.739-2.694-.608.542zM86.061 9.482C84.909 8.33 83.559 7.7 81.689 7.7c-1.326 0-2.828.413-3.828 1.325V0h-4v25h4v-9.365c0-3.826 2.718-4.13 3.675-4.13 2.391 0 2.325 2.239 2.325 3.587V25h4v-9.887c0-2.37-.495-4.326-1.8-5.631'/%3E%3Cpath fill='%23FF3D2E' d='M65.043 13.438a2.891 2.891 0 1 1 0 5.784 2.891 2.891 0 0 1 0-5.784'/%3E%3Cpath fill='%23FFF' d='M17.261 18.721c-1.521 1.565-3.587 2.413-5.761 2.413-4.456 0-7.696-3.5-7.696-8.304 0-4.826 3.24-8.326 7.696-8.326 2.153 0 4.196.847 5.74 2.391l.608.609 2.674-2.674-.587-.609C17.718 1.938 14.718.7 11.5.7 4.935.7 0 5.917 0 12.851 0 19.764 4.957 24.96 11.5 24.96c3.24 0 6.24-1.26 8.457-3.543l.587-.609-2.652-2.717-.631.63z'/%3E%3C/svg%3E"
                }
                alt="mobile app development company"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="home-section3">
        <Fade bottom>
          <div className="mid-title">
            <p>You dream, we fulfill</p>
          </div>

          <p className="mid-para">
            We are on a mission to help you become better in your field. As a
            group of software development experts,
            <br />
            we believe in sharing our years of experience to make your dream
            fulfil.{" "}
          </p>
        </Fade>
        <div className="overlap-div rightside">
          <div className="image left hvr-grow">
            <Image src={Proddev} alt="mobile app development company" style={{width:"100%", height:"100%"}}/>
          </div>

          <Fade bottom>
            <div className="info-div">
              <p className="red-heading">
                the strategy helps us to build your business
              </p>
              <div className="grey-container">
                <div className="list-items">
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Software Development Services</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Digital Transformation</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">
                      Data Science &amp; Analytics Solutions
                    </p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Business Intelligence</p>
                  </div>
                </div>
                <div className="list-items">
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">
                      AI Software Development Services
                    </p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Cloud Computing</p>
                  </div>
                  {/* <Button className='next-button btn-animated'>
                                        <i className='icon red-arrow' />
                                    </Button> */}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="overlap-div leftside">
          <div className="image left hvr-grow">
            <Image src={Ourtech} alt="mobile app development company" style={{width:"100%", height:"100%"}} />
          </div>
          <Fade bottom>
            <div className="info-div">
              <p className="red-heading">What Technology Do We Employ?</p>
              <div className="grey-container">
                <div className="list-items">
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Kotlin</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Swift</p>
                  </div>

                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">React Native</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Blockchain</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Unity 3D</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Java</p>
                  </div>

                  {/* <Button className='next-button btn-animated'>
                                        <i className='icon red-arrow' />
                                    </Button> */}
                </div>
                <div className="list-items">
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Flutter</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">AI/ML</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Python</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">TensorFlow</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Angular.JS</p>
                  </div>
                  <div className="item">
                    <i className="icon redstar" />
                    <p className="item-text">Vue.js</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="home-section4">
        <Fade bottom>
          <p className="midtitle">Our Work Process</p>
        </Fade>
        <div className="process-circle">
          <Zoom>
            <Row lg={8} className="work-process-image"></Row>
          </Zoom>

          <div className="step1 step">
            <Zoom>
              <p className="stepnumber">01</p>
              <p className="step-text">IDEA</p>
              <p className="step-subtext">
                We believe that deep understanding must be required to match
                your idea and dream.
              </p>
            </Zoom>
          </div>

          <div className="step2 step">
            <Zoom>
              <p className="stepnumber">02</p>
              <p className="step-text">PROJECT BRIEF</p>
              <p className="step-subtext">
                Our experts introduce you to the processes and talents needed to
                make your project more creative and successful.
              </p>
            </Zoom>
          </div>

          <div className="step3 step">
            <Zoom>
              <p className="stepnumber">03</p>
              <p className="step-text">RESERCH</p>
              <p className="step-subtext">
                Our highly dedicated team of professional works to make your
                dream come true by doing conventional research on your idea.
              </p>
            </Zoom>
          </div>

          <div className="step4 step">
            <Zoom>
              <p className="stepnumber">04</p>
              <p className="step-text">DESIGNS</p>
              <p className="step-subtext">
                Our creative team works tirelessly to get the best look and feel
                of your product with professionalism and easy-to-update feature
              </p>
            </Zoom>
          </div>
          <div className="step5 step">
            <Zoom>
              <p className="stepnumber">05</p>
              <p className="step-text">DEVELOPMENT</p>
              <p className="step-subtext">
                A great website is your first step to walking into the online
                business, we create your steps more impactful.
              </p>
            </Zoom>
          </div>
          <div className="step6 step">
            <Zoom>
              <p className="stepnumber">06</p>
              <p className="step-text">TESTING</p>
              <p className="step-subtext">
                No site is ready without doing some set of testing on different
                devices and phones.
              </p>
            </Zoom>
          </div>
          <div className="step7 step">
            <Zoom>
              <p className="stepnumber">07</p>
              <p className="step-text">GO LIVE</p>
              <p className="step-subtext">
                With your final approval, we go for final launching. We launch
                your website, announcing it on social media.
              </p>
            </Zoom>
          </div>
        </div>
      </div>
      <div className="home-section5">
        <Row>
          <Col lg={3} className="textside">
            <div className="text-block">
              <p className="heading">Testimonials</p>
              <p className="para">
                What clients
                <br />
                think of us !!
              </p>
            </div>
          </Col>
          <Col lg={9} className="sliderside">
            <Slider {...settings} className="slider-styles">
              {sliderItemsArray.map((item, index) => {
                return (
                  <>
                    <div className="item-box" id={item.id}>
                      <div className="whitebox">
                        <img src={item.icon} alt="software development" style={{width:"100%", height:"100%"}}/>
                      </div>
                      <i className="icon redDoubleQoutes"></i>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.name }}
                        className="name"
                      ></p>
                      <p className="para-style">{item.para}</p>
                    </div>
                  </>
                );
              })}
              <div className=""></div>
            </Slider>
          </Col>
        </Row>
      </div>

      <div className="contact-section">
        <Fade bottom>
          <p className="midtitle">
            Estimations &amp; planning for business decisions
          </p>
          <p className="midsmalltext">
            Connect with our expertise for more discussion{" "}
          </p>
        </Fade>
        <div className="contact-box">
          <Row>
            <Col lg={8}>
              <form encType="multipart/form-data" onSubmit={submitFormHandler}>
                <div className="contact-form">
                  <input
                    className="contact-input"
                    type={"text"}
                    placeholder="Full Name"
                    name={"name"}
                    onChange={handleChange}
                    value={contactFormFields.name}
                  />
                  <Row>
                    <Col lg={6}>
                      <input
                        className="contact-input contact-number"
                        type={"text"}
                        placeholder="Contact Number"
                        name={"phone"}
                        onChange={handleChange}
                        value={contactFormFields.phone}
                      />
                    </Col>
                    <Col lg={6}>
                      {" "}
                      <input
                        className="contact-input"
                        type={"text"}
                        placeholder="Email Address"
                        name={"email"}
                        onChange={handleChange}
                        value={contactFormFields.email}
                      />
                    </Col>
                  </Row>
                  <textarea
                    className="textarea"
                    placeholder="Project Description"
                    name={"projectdescription"}
                    onChange={handleChange}
                    value={contactFormFields.projectdescription}
                  ></textarea>
                  <Row className="bottom-row">
                    <Col lg={12}>
                      <Button className="btn contact-form-btn" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </form>
            </Col>
            <Col lg={4}>
              <div className="right-side-inner">
                <Fade bottom>
                  <p className="bigtext">
                    Let's Get into
                    <br />
                    <span className="red-text">Business discussions </span> !!
                  </p>
                  <p className="smallText">
                    Our strength lies in the comprehensive suite of services we
                    offer to our clients.
                  </p>
                </Fade>
              </div>
            </Col>
          </Row>
        </div>
        <Fade bottom>
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext bottom">
            We’d like to hear from you and will be happy to answer your queries
          </p>
        </Fade>
      </div>
      <CustomToast
        show={showToast}
        onClose={handleHideToast}
        message={toastMessage}
      ></CustomToast>
    </>
  );
}

export default Home;
