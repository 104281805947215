import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import TeenPattiTwoPhonesNew from "../../../assests/images/TeenpattiNew.png";
import TeenPattiThreePhonesNew from "../../../assests/images/TeenPattiThreePhonesNew.png";
import TeenpattiFeature1 from "../../../assests/images/TeenpattiFeatures1.png";
import TeenpattiFeature2 from "../../../assests/images/TeenpattiFeatures2.png";
import TeenpattiFeature3 from "../../../assests/images/TeenpattiFeatures3.png";
import TeenpattiFeature4 from "../../../assests/images/TeenpattiFeatures4.png";
import TeenpattiFeature5 from "../../../assests/images/TeenpattiFeatures5.png";
import TeenpattiFeature6 from "../../../assests/images/TeenpattiFeatures6.png";
import TeenpattiFeature7 from "../../../assests/images/TeenpattiFeatures7.png";
import TeenpattiFeature8 from "../../../assests/images/TeenpattiFeatures8.png";
import TeenpattiFeature9 from "../../../assests/images/TeenpattiFeatures9.png";
import TeenpattiFeature10 from "../../../assests/images/TeenpattiFeatures10.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function TeenPatti() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let features = [
    {
      icon: TeenpattiFeature1,
      name: "No Limit Classic",
      marginTop: false,
    },
    {
      icon: TeenpattiFeature2,
      name: "Lowest Joker",
      marginTop: true,
    },
    {
      icon: TeenpattiFeature3,
      name: "Pot Limit Classic",
      marginTop: true,
    },
    {
      icon: TeenpattiFeature4,
      name: "Joker",
      marginTop: false,
    },
    {
      icon: TeenpattiFeature5,
      name: "VIP Teen Patti",
      marginTop: true,
    },
    {
      icon: TeenpattiFeature6,
      name: "Muflis",
      marginTop: true,
    },
    {
      icon: TeenpattiFeature7,
      name: "AK47",
      marginTop: false,
    },
    {
      icon: TeenpattiFeature8,
      name: "Hukum",
      marginTop: true,
    },
    {
      icon: TeenpattiFeature9,
      name: "Highest Joker",
      marginTop: true,
    },
    {
      icon: TeenpattiFeature10,
      name: "10x Boot",
      marginTop: false,
    },
  ];
  return (
    <>
    
      {/* <HelmetComponent
        title={"Teen Patti Game Development Company - Digital Crew "}
        description={
          "Digital Crew is a leading teen patti game development company that focuses on making well designed, immersive games with a strong focus on gaming experience."
        }
        url="industries/games/teen-patti"
      /> */}
      <SEO
        title="Teen Patti Game App Development | 3 Patti Game Development Company"
        description="Digital Crew is the leading Teen Patti game app development company that offers secured 3 Patti game development services at a very reasonable cost.
        "
        url="https://digitalcrew.co.in/industries/games/teen-patti"
      />
      <HeroComponent
        bigText={
          "We develop a highly featured<br />Teen Patti mobile game app development"
        }
        smallText={
          "Explore the best virtual card Teen Patti mobile game<br />app development with us."
        }
        buttonText={"Consult Our Experts"}
        page={"TeenpattiHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>
            <span className="active"> &gt; TeenPatti</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">TeenPatti Game Development Company</h1>
              <p className="text">
                Digital Crew is the best Teen Patti development company in
                India. Our Teen Patti game app developers create popular
                software and entertaining card games that boost your business
                presence in the marketplace. Our specialised team of Teen Patti
                game app developers will ensure card games with the top UI/UX
                features are designed to suit all your precise needs. It is the
                most loved game by all the population. With evolving, it’s
                important for game developers to keep an eye on updates and
                innovation to improve our services. Digital Crew develops Teen
                Patti game with variations as per our client’s needs.
              </p>

              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">20+</div>
                    <div className="smalltext">
                      Teen Patti Apps
                      <br />
                      Delivered .
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side">
              <Image
                src={TeenPattiTwoPhonesNew}
                alt="Teen Patti App Development"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                We are the best Teen Patti Game Development Company in India. We
                build online apps with advanced and latest technology. Hire Teen
                Patti game developers from our pool of experts who offer
                multiple feature apps like payment security, play as guests,
                more variations, multi-language, with in-app purchases and daily
                bonuses. An innovative app requires a rich gameplay experience
                from our professional developers.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image
                src={TeenPattiThreePhonesNew}
                alt="Teen Patti Game App Development"
              />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Teenpatti Features</p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={2}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles uneven-columns margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="Teen Patti game software Development"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            Connect with our experts for your custom Teen Patti igmaing
            solution, and mark your presence in the competitive gaming world.
            <br />
            You will explore more features to need to add in your product with
            Digital Crew’s solutions.
          </p>
        </div>
      </div>
    </>
  );
}

export default TeenPatti;
