import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import Trivia2Phones from "../../../assests/images/TriviaNew.png";
import Trivia3Phones from "../../../assests/images/TriviaThreePhoneNew.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function Trivia() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <HelmetComponent title={"Trivia Game Development Company - Digital Crew "}  description={"Digital Crew is a digital and igaming solution providing company that is an expert in trivia game development."} url="industries/games/trivia" />   */}
      <SEO
        title="Trivia Game App Development Company | Best Quiz App Development Services"
        description="Digital Crew offers user-friendly Trivia Game app development services for Android and iOS to provide fantastic engagement to your users. 
        "
        url="https://digitalcrew.co.in/industries/games/trivia"
      />
      <HeroComponent
        bigText={
          "We serve the best Trivia game<br/>development services across the country"
        }
        smallText={
          "Most trusted Trivia gaming app and software<br/>development company."
        }
        buttonText={"Consult Our Experts"}
        page={"TriviaHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>
            <span className="active"> &gt; Trivia</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">Trivia Game App Development Company</h1>
              <p className="text">
                Digital Crew is a digital and igaming solution providing company
                that is an expert in Trivia App development. Our experts provide
                multi-platform mobile app solutions with on Andriod, iOS, and
                Windows. We are passionate to deliver quality products over
                quantity outputs. Digital Crew is the best Trivia app
                development company in India, our experts help you to understand
                what best fits for your business.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">15+</div>
                    <div className="smalltext">
                      Trivia Apps
                      <br />
                      Delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={Trivia2Phones} alt="trivia game app" />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                Our Trivia game developers keep updating and researching to
                offer value added features to your business. Trivia Quiz is one
                of the interesting games. The majority of people love it
                irrespective of their age. An experienced team makes your dream
                come true, by adding innovation and years of experience in
                building your product.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={Trivia3Phones} alt="create a trivia game online" />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Trivia Features</p>
          <p className="smalltext">
            Our expertise makes your product more user friendly by adding valued
            features. Digital Crew developers use the latest technology to build
            a perfect Trivia game as per your requirement. This makes us a top
            notch Trivia game development company in India.
          </p>
          <div className="card-section trivia-feature-section">
            <Row>
              <Col lg={4}>
                <div className="list-card">
                  <ul>
                    <li>Captivating aesthetic and UI</li>
                    <li>Quick display of correct answers</li>
                    <li>Different levels of questions</li>
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                <div className="list-card">
                  <ul>
                    <li>Timer for answering quizzes’ questions</li>
                    <li>Customized User profiles</li>
                    <li>Leaderboard Display</li>
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                <div className="list-card">
                  <ul>
                    <li>Seamless navigation</li>
                    <li>Wide Topic selection for Quiz</li>
                    <li>Interesting &amp; innovative challenges for users</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            Let’s connect now to improve your igaming brand, by providing you
            with a Trivia game service
            <br />
            that can meet your customer expectations and your target business
            goals too.
          </p>
        </div>
      </div>
    </>
  );
}

export default Trivia;
