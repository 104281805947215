import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import BlackJack2Phones from "../../../assests/images/BlackjackTwoPhoneNew.png";
import BlackJack3Phones from "../../../assests/images/BlackjackThreePhoneNew.png";

import BlackjackFeature1 from "../../../assests/images/BlackjackFeature1.png";
import BlackjackFeature2 from "../../../assests/images/BlackjackFeature2.png";
import BlackjackFeature3 from "../../../assests/images/BlackjackFeature3.png";
import BlackjackFeature4 from "../../../assests/images/BlackjackFeature4.png";
import BlackjackFeature5 from "../../../assests/images/BlackjackFeature5.png";
import BlackjackFeature6 from "../../../assests/images/BlackjackFeature6.png";
import BlackjackFeature7 from "../../../assests/images/BlackjackFeature7.png";
import BlackjackFeature8 from "../../../assests/images/BlackjackFeature8.png";
import BlackjackFeature9 from "../../../assests/images/BlackjackFeature9.png";
import BlackjackFeature10 from "../../../assests/images/BlackjackFeature10.png";
import BlackjackFeature11 from "../../../assests/images/BlackjackFeature11.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function BlackJack() {
  let features = [
    {
      icon: BlackjackFeature1,
      name: "Multi Player",
      marginTop: false,
    },
    {
      icon: BlackjackFeature2,
      name: "World Class Security",
      marginTop: true,
    },
    {
      icon: BlackjackFeature3,
      name: "Multi-Language",
      marginTop: false,
    },
    {
      icon: BlackjackFeature4,
      name: "Back Office & CRM",
      marginTop: true,
    },
    {
      icon: BlackjackFeature5,
      name: "Rewards & Loyalty",
      marginTop: true,
    },
    {
      icon: BlackjackFeature6,
      name: "Payment System",
      marginTop: false,
    },
    {
      icon: BlackjackFeature7,
      name: "Anti Fraud System",
      marginTop: true,
    },
    {
      icon: BlackjackFeature8,
      name: "RNG Certified Games",
      marginTop: false,
    },
    {
      icon: BlackjackFeature9,
      name: "Responsible Gaming",
      marginTop: true,
    },
    {
      icon: BlackjackFeature10,
      name: "Tournament",
      marginTop: false,
    },
    {
      icon: BlackjackFeature11,
      name: "Risk Management",
      marginTop: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      {/* <HelmetComponent title={"Blackjack Game Development Company - Digital Crew"} description={"Digital Crew is leading blackjack game development company that offers edge cutting software solutions to deliver an engaging user experience."} url="industries/games/blackjack" /> */}
      <SEO
        title="Blackjack Game Development Company | Blackjack Game Development Services"
        description="Being a Top Blackjack Game Development Company, Digital Crew provides the top blackjack game app development services with the help of their expert game developers."
        url="https://digitalcrew.co.in/industries/games/blackjack"
      />
      <HeroComponent
        bigText={
          "Complete digital transformation and<br/>feature-packed mobile app development"
        }
        smallText={
          "Scalable custom software development solutions that<br/> fulfil your business needs."
        }
        buttonText={"Consult Our Experts"}
        page={"BlackjackHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>
            <span className="active"> &gt; BlackJack</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">BlackJack Game Development Company</h1>
              <p className="text">
                Digital Crew is one of the top-rated BlackJack game development
                companies in India. Our experts of BlackJack game developers
                excel in providing with best BlackJack software. Our priority is
                to provide the best quality BlackJack game to our clients. Hire
                a skilled developer, who can create valuable features and game
                applications that offer seamless performance on iOS, Android and
                other platforms. We have a BlackJack card counting app that will
                help users to know everything about the BlackJack game. It has
                increased more opportunities for the game players around the
                world.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">15+</div>
                    <div className="smalltext">
                      BlackJack Apps
                      <br />
                      delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side">
              <Image
                src={BlackJack2Phones}
                alt="Blackjack Game Software Development"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                Digital Crew has the expertise of BlackJack software and app
                providers who develop UI for the multiplayer. A feature rich
                services are like the live BlackJack game app in 2D and 3D by
                using the latest technologies with cross-browser functionality.
                We provide a guarantee service of BlackJack game functionality
                that could be tailored as per the BlackJack fans.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image
                src={BlackJack3Phones}
                alt="Blackjack Game App Development Services"
              />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Blackjack Features</p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    lg={2}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles  margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="Blackjack Game App Development"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            Connect with Digital Crew BlackJack game development services for
            edge cutting software solutions to deliver an engaging user
            experience.
            <br />
            We help you to meet your needs and help you to achieve your business
            goals too. Hire the best developer for all igaming solutions.{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default BlackJack;
