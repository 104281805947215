import React from 'react'
import { Image } from 'react-bootstrap';

function PersonDesc(props) {
    const { 
        profileImg,
        name,
        designation } = props;
    return (
        <div className='person-desc-box'>
            <div className='image-box'>
                <Image src={profileImg}></Image>
            </div>
            <p className='person-name'>{name}</p>
            <p className='person-designation'>{designation}</p>
        </div>
    )
}

export default PersonDesc