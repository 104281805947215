import React from 'react'
import { Col, Image, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

function ServiceCard(props) {
    const {
        heading,
        para,
        servicesHeading,
        serviceSteps,
        type,
        reverse
    } = props;
    console.log(reverse);
    return (
        <div className={reverse === true ? 'service-card-container reverse' : 'service-card-container '}>
            <Row>
                <Col lg={7} md={8}>
                    <Fade >
                        <h2 className='heading'>
                            {heading}
                        </h2>
                        <p className='service-para' dangerouslySetInnerHTML={{ __html: para }}></p>
                        <div className='services'>
                            <h2>{servicesHeading}</h2>
                            <ul className='services-lists'>
                                {
                                    serviceSteps.map((item, index) => {
                                        return (
                                            <>
                                                <li className='service-step'><i className='icon black-list-arrow' /> {item} </li>
                                            </>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </Fade>
                </Col>
                <Col lg={5} md={4}>
                    <Fade>
                        <div className={`image-container-${type} image-container`}>
                        </div>
                    </Fade>
                    <div className="link-container">
                        <a href={`/services/${type}`} className="service-link">Know more</a> <i className='icon know-more-arrow' />
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default ServiceCard