import axios from "axios";

export const submitContactForm = async (payLoad) => {
  try {
    const { data } = await axios.post(`https://api.hsforms.com/submissions/v3/integration/submit/22503493/ee5dd066-dec1-42fd-a7e9-68877f9a7ef3`, payLoad, {
      headers: {
        'Content-Type': 'application/json',
        "withCredentials": "true"
      }
    });
    return data;
  } catch (err) {
    return err;
  }
};


export const uploadFile = async (payLoad) => {
  try {
    const { data } = await axios.post(`https://api.hubapi.com/filemanager/api/v3/files/upload?hapikey=pat-na1-12866a9f-b1ce-46c1-a59f-a1406b94a7f6`, payLoad, {
      headers: {  
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  } catch (err) {
    return err;
  }
};