import React from 'react'
import Fade from 'react-reveal/Fade';

function SpecialCard(props) {
    const {
        nextBigText,
        nextSmallText,
        bigBGImage,
        linkToPage,
        number
    } = props;
    return (
        <>
            <div className="big-card">
                <div className="next-box">
                    <Fade left>
                        <div className="bigNumber">
                            {number}
                        </div>
                        <p>{nextBigText}</p>
                        <div className='desc-text'>{nextSmallText} <a href={`/industries/${linkToPage}`}><i className='icon black-arrow ' /></a></div>
                    </Fade>
                </div>
                <div className={`card-inner ${bigBGImage}`}></div>
            </div>
        </>
    )
}

export default SpecialCard