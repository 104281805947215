
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import PublicLayout from "../layout/PublicLayout";


const RoutesComponent = () => {
  return (
  
      <Switch>
        <Route  path="/" component={PublicLayout}></Route>      
      </Switch>
    
  );
};

export default RoutesComponent;