import React, { useEffect } from "react";
import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../components/Hero";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";

import DigitalOcean from "../../assests/images/digitalocean.png";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";
import HashtagLogo from "../../assests/images/hashtagLogo.png";
import metaverseIcon1 from "../../assests/images/metaverseSliderIcon1.png";
import metaverseIcon2 from "../../assests/images/metaverseSliderIcon2.png";
import metaverseIcon3 from "../../assests/images/metaverseSliderIcon3.png";
import metaverseIcon4 from "../../assests/images/metaverseSliderIcon4.png";
import Sap from "../../assests/images/sap.png";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";

function Metaverse() {
  var settings = {
    dots: false,
    infinite: false,

    slidesToShow: 4,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: metaverseIcon1,
      name: "3D virtual space",
      id: 1,
      para: "Better opportunity to build a 3D virtual world without any limitation and grow various industries without any borders.",
    },
    {
      icon: metaverseIcon2,
      name: "Decentralization",
      id: 2,
      para: "We have integrated the best AR/VR and blockchain services for developing a decentralized platform for more growth opportunities.",
    },
    {
      icon: metaverseIcon3,
      name: "Digital currency",
      id: 3,
      para: "By developing an impressive platform for your digital currency business, give users full freedom to buy or sell anything at any time.",
    },
    {
      icon: metaverseIcon4,
      name: "Security",
      id: 4,
      para: "To protect users’ personal information, we have highly advanced technologies to maintain users’ safety and security systems.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
  
      <SEO
        title="Metaverse Development Company | Hire Metaverse Developers "
        description="Digital Crew is a prominent Metaverse development company specializing in Metaverse Games, NFT marketplace, 3D Spaces, and app development services."
        url="https://digitalcrew.co.in/industries/metaverse"
      />
      {/* <HelmetComponent
        title={"Metaverse Software Development Services - Digital Crew"}
        description={
          "Digital Crew is the finest metaverse development service company. let’s connect with us for your metaverse software development services."
        }
        url="services/metaverse"
      /> */}
      <div>
        <HeroComponent
          bigText={"Let’s unfold the massive opportunity by using Metaverse"}
          smallText={
            "Connect with a virtual world that will be value-added to your business."
          }
          buttonText={"Talk to our Experts"}
          page={"MetaverseHomeBanner"}
        ></HeroComponent>
        <div className="metaverse-section">
          <div className="section-breadcrumb">
            <p className="breadcrumb">
              <a href="/industries">Industries</a>
              <span className="active"> &gt; Metaverse</span>
            </p>
          </div>
          <div className="section1">
            <div className="mid-div">
              <p className="midtitle">TRUSTED BY</p>
            </div>
            <div className="sponsers">
              <Image src={Aws} alt="Metaverse Development Solutions" />
              <Image src={DigitalOcean} alt="metaverse companies in india" />
              <Image src={HashtagLogo} alt="metaverse developer" />
              <Image
                src={Cloudera}
                alt="Metaverse NFT Marketplace Development"
              />
              <Image src={Sap} alt="Metaverse NFT Marketplace Development" />
            </div>
          </div>
          <div className="section2">
            <p className="midText">
              Digital Crew is the finest metaverse development service company
            </p>
            <p className="midSmallText">
              The potential of metaverse exists across endless use cases and
              Digital Crew is adept at meeting all of them with its services.
              <br />
              We offer an extensive range of enterprise-focused metaverse
              development services to help businesses thrive in the evolving
              virtual world.
            </p>
            <div className="block-section">
              <Row>
                <Col lg={6} md={6} className="card-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon1" />
                      Metaverse application development
                    </div>
                    <div className="lower-section">
                      Our digital crew metaverse application development experts
                      have deep knowledge of the latest technologies such as
                      Blockchain, AI &amp; ML, and AR/VR with exciting features.
                      We provide Metaverse development as per your business
                      needs, just share your idea with our consultant. We’ll
                      come up with the most appropriate tech approach to your
                      table.
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} className="card-style box-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon2" />
                      Metaverse NFT marketplace development
                    </div>
                    <div className="lower-section">
                      To create a more interesting platform for buying and
                      trading NFTs, we launch your business on your metaverse
                      NFT marketplace. We provide a proper development of the
                      NFT marketplace from scratch or wrap a white label
                      solution into a manifest design. An intuitive storefront,
                      trading mechanisms, crypto wallets, smart protocols, or
                      integrations on top of a famous NFT marketplace with all
                      safety and security.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} className="card-style box-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon3" />
                      Metaverse game development
                    </div>
                    <div className="lower-section">
                      We work on various Metaverse game development with our
                      innovative services. Metaverse gaming attracts users to
                      spend more time on your gaming platform, this gaming is a
                      reality based experience in a virtual world. Our creative
                      Metaverse game development service will take off your
                      gaming business to a new height. Connect with Digital Crew
                      professionals.
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} className="card-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon4" />
                      Metaverse integration services
                    </div>
                    <div className="lower-section">
                      We build incredible customized services by integrating the
                      latest and most advanced technologies with a 100%
                      hassle-free user experience. Our professionals create
                      highly impressive virtual environments that allow users to
                      interact, hang out, learn, or even work. We always
                      implement value added integrations to your custom
                      metaverse development services. Integration is the new key
                      to creating engagement.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} className="card-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon5" />
                      Metaverse social media app development
                    </div>
                    <div className="lower-section">
                      We permit organisations to track the potential of the
                      metaverse by offering various social media development
                      services. Our expertise extends to several aspects of
                      metaverse social media, including social media dApps,
                      ecosystem migration, and 3D platform development. We help
                      you to launch an immersive next generation metaverse
                      platform. Developers build a social media metaverse that
                      offers good social interaction through vivid virtual
                      experience.
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} className="card-style box-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon6" />
                      Metaverse decentralized platform
                    </div>
                    <div className="lower-section">
                      Digital Crew’s experts use highly advanced technology to
                      build a custom metaverse decentralized platform tailored
                      to your specified project requirements. We provide
                      impressive UI/UX designs for a more user friendly
                      platform. Metaverse decentralized platforms are scalable
                      and pocket friendly. Connect with our experts for the
                      leading world class metaverse services to enhance user
                      experience.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} className="card-style box-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon7" />
                      Metaverse 3D Spaces
                    </div>
                    <div className="lower-section">
                      Our skilled metaverse developers provide you with the best
                      development of multiple 3D space designs for your custom
                      metaverse development service. We create an impressive
                      approach with the best 3D modelling, visualization, and
                      interoperability. We deliver cutting edge 3D
                      visualizations to create realistic experiences by
                      presenting the best of your visuality.
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} className="card-style">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon metaverseIcon8" />
                      Metaverse tourism development
                    </div>
                    <div className="lower-section">
                      Let’s explore the best of metaverse tourism, by connecting
                      with our experts of metaverse developers that have a clear
                      vision of the tourism industry. They focused on particular
                      activities regarding the short term movement of people
                      from where they live. It includes transportation, guest
                      accommodation, entertainment, and food and beverages.
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="section3">
            <p className="black-heading">
              Highlighted Features in Our Metaverse Development Projects
            </p>
            <p className="white-heading">
              We have years of experience in metaverse development, to build a
              seamless software development
              <br />
              service to enhance your business presence in the competitive
              world.
            </p>
            <div className="center-box">
              <Slider {...settings} className="slider-styles">
                {sliderItemsArray.map((item) => {
                  return (
                    <>
                      <div className="item-box" id={item.id}>
                        <div className="whitebox">
                          <img
                            src={item.icon}
                            alt="metaverse development company"
                          ></img>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.name }}
                          className="name"
                        ></p>
                        <p className="para-style">{item.para}</p>
                      </div>
                    </>
                  );
                })}
                <div className=""></div>
              </Slider>
            </div>
          </div>
          <div className="section4">
            <p className="heading">Tech Stack</p>
            <div className="redbox">
              <div className="list-div">
                <p className="list-heading">Front &amp; Back end</p>
                <p className="list-item">React JS</p>
                <p className="list-item">Angular JS</p>
                <p className="list-item">Node JS</p>
                <p className="list-item">Spring Boot</p>
                <p className="list-item">Unity 3D</p>
                <p className="list-item">Bootstrap/ tailwind css</p>
              </div>
              <div className="list-div">
                <p className="list-heading">DevOps tools</p>
                <p className="list-item">AWS</p>
                <p className="list-item">Docker</p>
                <p className="list-item">Kovenates</p>
                <p className="list-item">Jenkins</p>
                <p className="list-item">Git</p>
              </div>
              <div className="list-div">
                <p className="list-heading">QA tools</p>
                <p className="list-item">Cypress</p>
                <p className="list-item">Selenium</p>
                <p className="list-item">JMeter</p>
              </div>
              <div className="list-div">
                <p className="list-heading">Mobile</p>
                <p className="list-item">React Native</p>
                <p className="list-item">Flutter</p>
                <p className="list-item">Unity 3D</p>
                <p className="list-item">Ionic</p>
                <p className="list-item">Swift</p>
                <p className="list-item">Android Studio</p>
              </div>
            </div>
          </div>
          <div className="section5">
            <p className="midText">
              Digital Crew’s Metaverse Software Development Process
            </p>
            <p className="midSmallText">
              As the experienced metaverse tech stack developer, we have unique
              opportunities to create your dream
              <br />
              project into reality, by using innovative and creative processes.
              Here we help you to mark your business
              <br />
              in the competitive world that’s full of challenges. Let’s connect
              with us for your metaverse software
              <br />
              development. We have a pattern to work out every complex process
              in an easy way.
            </p>
            <div className="process-section">
              <Row>
                <Col lg={4}>
                  <div className="process">
                    <div className="bigNumber">01</div>
                    <p className="redText">Requirements elicitation</p>
                    <p className="para">
                      Our experts believe that only proper communication and
                      understanding, help to create an idea into reality, it’s
                      important of understanding the project’s requirements,
                      resources and information to start with any process.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="process">
                    <div className="bigNumber">02</div>
                    <p className="redText">UI/UX design</p>
                    <p className="para">
                      It’s important to maintain the user’s interest in the
                      platform for better engagement, we use highly innovative
                      designs and competitive tools to create an impressive
                      platform so users can enjoy and interact freely for hours.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="process">
                    <div className="bigNumber">03</div>
                    <p className="redText">Prototype</p>
                    <p className="para">
                      To create the exact product you dreamt about, our
                      metaverse developers get early feedback on the product,
                      after confirming your vision we will move forward only. We
                      believe in customer satisfaction.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="process">
                    <div className="bigNumber">04</div>
                    <p className="redText">Development</p>
                    <p className="para">
                      Strong development required great knowledge about the
                      latest tools and technologies that what goes better with
                      your business development. Every aspect of business
                      demands a better development process.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="process">
                    <div className="bigNumber">05</div>
                    <p className="redText">Testing</p>
                    <p className="para">
                      Even a highly developed product needs a proper testing
                      process. The developed product is tested to ensure
                      quality, and we provide 100% bug and hassle free software
                      applications with zero compromises.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="process">
                    <div className="bigNumber">06</div>
                    <p className="redText">Post maintenance</p>
                    <p className="para">
                      Our team will continuously assist and support you even
                      after the product is launched as per the terms of the
                      contract, the team will always assist and guide you for
                      more growth opportunities. Strong services reflect the
                      strength of the organization.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Metaverse;
