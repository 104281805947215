import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import Rummy2Phones from "../../../assests/images/RummyPhoneNw.png";
import Rummy3Phones from "../../../assests/images/RummyPhoneNewLand.png";
import RummyFeature1 from "../../../assests/images/RummyFeature1.png";
import RummyFeature2 from "../../../assests/images/RummyFeature2.png";
import RummyFeature3 from "../../../assests/images/RummyFeature3.png";
import RummyFeature4 from "../../../assests/images/RummyFeature4.png";
import RummyFeature5 from "../../../assests/images/RummyFeature5.png";
import RummyFeature6 from "../../../assests/images/RummyFeature6.png";
import RummyFeature7 from "../../../assests/images/RummyFeature7.png";
import RummyFeature8 from "../../../assests/images/RummyFeature8.png";
import RummyFeature9 from "../../../assests/images/RummyFeature9.png";
import RummyFeature10 from "../../../assests/images/RummyFeature10.png";
import RummyFeature11 from "../../../assests/images/RummyFeature11.png";
import RummyFeature12 from "../../../assests/images/RummyFeature12.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function Rummy() {
  let features = [
    {
      icon: RummyFeature1,
      name: "Point Rummy",
      marginTop: false,
    },
    {
      icon: RummyFeature2,
      name: "Pool Rummy",
      marginTop: true,
    },
    {
      icon: RummyFeature3,
      name: "Deal Rummy",
      marginTop: false,
    },
    {
      icon: RummyFeature4,
      name: "Tournaments",
      marginTop: true,
    },
    {
      icon: RummyFeature5,
      name: "Private Table",
      marginTop: false,
    },
    {
      icon: RummyFeature6,
      name: "Multi-Language",
      marginTop: true,
    },
    {
      icon: RummyFeature7,
      name: "Rewards & Loyalty",
      marginTop: false,
    },
    {
      icon: RummyFeature8,
      name: "Back Office & CRM",
      marginTop: true,
    },
    {
      icon: RummyFeature9,
      name: "RNG Certified Games",
      marginTop: false,
    },
    {
      icon: RummyFeature10,
      name: "Anti Fraud System",
      marginTop: true,
    },
    {
      icon: RummyFeature11,
      name: "Responsible Gaming",
      marginTop: false,
    },
    {
      icon: RummyFeature12,
      name: "Payment Security",
      marginTop: true,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      {/* <HelmetComponent title={"Rummy Game Development Company"}  description={"Digital Crew is known as the best rummy game development company. Get customized Rummy game solution across the globe with high features"} url="industries/games/rummy" />    */}
      <SEO
        title="Rummy Game App Development | Rummy Game Development Services"
        description="Digital Crew is India's top rummy game software development company, having expertise in rummy app and website development services."
        url="https://digitalcrew.co.in/industries/games/rummy"
      />
      <HeroComponent
        bigText={"India’s leading Rummy<br/>game development company"}
        smallText={
          "Get customized Rummy game solution across<br/>the globe with high features."
        }
        buttonText={"Consult Our Experts"}
        page={"RummyHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>
            <span className="active"> &gt; Rummy</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">Rummy Game App Development</h1>
              <p className="text">
                Introducing one of the leading igaming solution companies.
                Digital Crew is known as the best rummy game development company
                in India. Here we assist you with all rummy game app development
                to make it an adventurous experience and help you to expand your
                existing online igaming business. We have a skilled unit for
                igaming app development solutions, our experts assist you
                throughout the entire app development journey.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">40+</div>
                    <div className="smalltext">
                      Rummy Apps
                      <br />
                      Delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side rummy-right-side-phone">
              <Image src={Rummy2Phones} alt="Rummy app development" />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                Digital Crew is the best rummy game development company in
                India. Our skilled team provide seamless features like a private
                table, paying online with real money, quick withdrawal and
                deposit money, complete lobby view, buddy live list and
                invitation, game history, and many more. To make rummy games
                easier and more convenient for the user, we have added various
                features to the gaming solutions. We add creativity and
                innovation to make your gaming solutions more successful.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={Rummy3Phones} alt="Rummy game development company" />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Rummy Features</p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={2}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="Rummy Game Software Development"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            At Digital Crew, we provide the best rummy development solution as
            per your requirement with expert guidance.
            <br />
            Let’s jump in for the perfect game development app for your
            business.
          </p>
        </div>
      </div>
    </>
  );
}

export default Rummy;
