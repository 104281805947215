import React from 'react'
import { Button } from 'react-bootstrap'

function PortfolioCard(props) {

    const {
        heading,
        text,
        rightImage,
        red,
    } = props;
    return (
        <div className={red ? "portfolio-card red" : "portfolio-card grey"}>
            <div className="portfolio-card-upper">
                <div className='left-side'>
                    <h2 className="heading">
                        {heading}
                    </h2>
                    <p className={red ? "description white" : "description grey"}>
                        {text}
                    </p>
                    <Button className={red ? 'btn-next white' : "btn-next red"}>Go Inside <i className={red ? "icon red-arrow-portfolio" : "icon goinside-white-icon "}></i> </Button>
                </div>
                <div className={`right-side ${rightImage}`}>

                </div>

            </div>
            <div className="portfolio-card-lower">
                <div className="tech-icons">
                    <p className={"text"}>Technology we use </p>
                    <i className={red ?'icon spring-boot-white' : 'icon spring-boot-black'} />
                    <i className={red ?'icon framework-white' : 'icon framework-black'} />
                    <i className={red ?'icon javascript-white' : 'icon javascript-black'} />
                    <i className={red ?'icon react-white' : 'icon react-black'} />
                </div>
            </div>
        </div>
    )
}

export default PortfolioCard