import React from 'react'
import FloatingWhatsApp from 'react-floating-whatsapp'
import Elisha from '../../assests/images/elishaRuth.png';
function Whatsapp() {
    return (
        <>
        <FloatingWhatsApp
            phoneNumber="+91-8319910098"
            accountName="Digital Crew, India"
            allowClickAway
            notification
            notificationDelay={60000} // 1 minute
            notificationSound
            avatar={Elisha}
        
            className="floating-button-custom"
        />
        </>
    )
}

export default Whatsapp