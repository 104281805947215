import React, { useEffect, useState } from "react";
import HeroComponent from "../../components/Hero";
import { Button, Col, Image, Row } from "react-bootstrap";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import HashtagLogo from "../../assests/images/hashtagLogo.png";
import Slider from "react-slick";
import SearchBigIcon from "../../assests/images/SearchBigIcon.png";
import ComputerSearch from "../../assests/images/ComputerSearch.png";
import PenBigIcon from "../../assests/images/PenBigIcon.png";
import SparkleBigIcon from "../../assests/images/SparkleIcon.png";
import Image1 from "../../assests/images/thinking-design-new.png";
import { submitContactForm } from "../../services";
import Fade from "react-reveal/Fade";
import CustomToast from "../../components/Toast";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";

function ThinkingAndDesign() {
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };
  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };
  var designProcess = [
    {
      heading: "Discover",
      icon: SearchBigIcon,
      content: "Research customer behaviours to meet customers’ needs.",
    },
    {
      heading: "Strategize",
      icon: ComputerSearch,
      content: "Formulate a strategy to achieve all targeted goals.",
    },
    {
      heading: "Design",
      icon: PenBigIcon,
      content: "Impactful designs that engaged your target audience.",
    },
    {
      heading: "Create",
      icon: SparkleBigIcon,
      content: "Testing to create more scale and easily accept user products.",
    },
  ];
  return (
    <>
      <SEO
        title="Thinking & Design Services and Solutions - Digital Crew
        "
        description="Digital Crew offers innovative Thinking & Design Services and Solutions to unlock creative potential and deliver impactful business design solutions"
        url="https://digitalcrew.co.in/services/thinking-and-design"
        keywords="digital services, website development company, top web development companies, website development agencies,web design"
      />
      {/* <HelmetComponent title={"Thinking & Design Services and Solutions - Digital Crew"} description={"We create value added designs by combining imagination, creativity, and innovation. Hire a creative designer for the development of your software, app and web services."} url="services/thinking-and-design" /> */}

      <HeroComponent
        bigText={"Building Digital Experiences With Creativity"}
        smallText={
          "Our designing team, think about your idea and build creative software out of it."
        }
        buttonText={"Book Our Demo"}
        page={"services-td"}
      ></HeroComponent>
      <div className="thinking-design-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/services">Services</a>
            <span className="active"> &gt; Thinking and Designing</span>
          </p>
        </div>
        <div className="section2">
          <p className="big-redText red">
            We design exceptional digital experiences
          </p>
          <p className="midtext blackbold">
            Designing is more than creating products and services
            <br />
            It can be applied to systems, procedures, protocols, and customer
            experiences
          </p>
          <div className="text-section">
            <p className="midtext one">
              In a world where users are highly dependent and relied on digital
              services. We create value added designs by combining imagination,
              creativity, and innovation. We build interfaces that are highly
              interactive and easy to use.
            </p>
            <p className="midtext second">
              We understand that major there is a gap between expectation and
              gratification. Our designing team thinks first about your idea and
              <br />
              gives research about it, then design your idea by combining all
              the essential elements like imagination, creativity, innovation,
              and empathy.
              <br />
              This way your product establishes a personal connection with end
              yours and creates an impact on their experience.
            </p>
            <p className="midtext third">
              Yes, we eat first with our eyes. We attract based on presentation.
              Hire a creative designer for the development of your software,
              <br />
              app and web services. Our experience and deep market understanding
              that our experts have garnered over the years.
              <br />
              We build genuine and satisfying experience products.
            </p>
          </div>
        </div>

        <div className="section3">
          <Row>
            <Col lg={6} md={6} className="left-side">
              <img src={Image1}></img>
            </Col>
            <Col lg={6} md={6} className="right-side-outer">
              <div className="right-side">
                <p className="heading">What we do</p>
                <p className="smallText">
                  Our expert design strategists visualize creative ideas and
                  present them in designs. We make changes and customized
                  designs as per requirements. We understand that a deep
                  connection with users’ expectations is important for better
                  business growth.
                </p>
              </div>
            </Col>
          </Row>
          <div className="list-section">
            <ul>
              <li>Business discovery and user research</li>
              <li>Product and marketing UX strategy</li>
              <li>User interface and visual design</li>
            </ul>
            <ul>
              <li>UX Workshops</li>
              <li>Usability testing and analytics</li>
              <li>Design sprints and prototyping</li>
            </ul>
            <ul>
              <li>Customer journey mapping</li>
              <li>Front-end engineering and platform integration</li>
            </ul>
          </div>
        </div>
        <div className="section4">
          <p className="mid-title">Our trusted pattern</p>
          <div className="sponsers">
            <Image src={Aws} />
            <Image src={DigitalOcean} />
            <Image src={HashtagLogo} />
            <Image src={Cloudera} />
            <Image src={Googledev} />
          </div>
        </div>
        <div className="section5">
          <h2 className="mid-title">Our designing processes</h2>
          <p className="midsmalltext">
            A digital solution can be delivered by any company
            <br />
            however, Digital Crew crafts your idea and deliver an experience
            that can be ever evolving.
          </p>
          <div className="mid-section">
            <Slider {...settings} className="slider-styles">
              {designProcess.map((item) => {
                return (
                  <>
                    <div className="process-box">
                      <div className="iconbox">
                        <img src={item.icon}></img>
                      </div>
                      <div className="redbox">
                        <p className="heading">{item.heading}</p>
                        <p className="content">{item.content}</p>
                      </div>
                    </div>
                  </>
                );
              })}
              <div className=""></div>
            </Slider>
          </div>
        </div>

        <div className="contact-section">
          <p className="midtitle">
            Share your estimate and custom plan for your business
          </p>
          <p className="midsmalltext">
            Our experts help you with the best designing solutions thoughtfully.
          </p>
          <div className="contact-box">
            <Row>
              <Col lg={8}>
                <form onSubmit={submitFormHandler}>
                  <div className="contact-form">
                    <input
                      className="contact-input"
                      type={"text"}
                      placeholder="Full Name"
                      name={"name"}
                      onChange={handleChange}
                      value={contactFormFields.name}
                    />
                    <Row>
                      <Col lg={6}>
                        <input
                          className="contact-input contact-number"
                          type={"text"}
                          placeholder="Contact Number"
                          name={"phone"}
                          onChange={handleChange}
                          value={contactFormFields.phone}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <input
                          className="contact-input"
                          type={"text"}
                          placeholder="Email Address"
                          name={"email"}
                          onChange={handleChange}
                          value={contactFormFields.email}
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="textarea"
                      placeholder="Project Description"
                      name={"projectdescription"}
                      onChange={handleChange}
                      value={contactFormFields.projectdescription}
                    ></textarea>
                    <Row className="bottom-row">
                      {/*  <Col lg={6}>
                                            <div className='file-div'>
                                                <input className='contact-input-file' type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.docx" placeholder="" name="file" onChange={handleChange} />
                                                <Button className='btn btn-file'>+</Button>
                                                {contactFormFields.file !== null && <p>{contactFormFields.file?.name}</p>}
                                            </div>

                                            <p>Attached File Less then 25 mB</p>
                                        </Col> */}
                      <Col lg={12}>
                        <Button className="btn contact-form-btn" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </Col>
              <Col lg={4}>
                <div className="right-side-inner">
                  <Fade bottom>
                    <p className="bigtext">
                      Let's Get into
                      <br />
                      <span className="red-text">Business discussions </span> !!
                    </p>
                    <p className="smallText">
                      Our strength lies in the comprehensive suite of services
                      we offer to our clients.
                    </p>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext bottom">
            We’d like to hear from you and will be happy to answer your queries
          </p>
        </div>
      </div>
      <CustomToast
        show={showToast}
        onClose={handleHideToast}
        message={toastMessage}
      ></CustomToast>
    </>
  );
}

export default ThinkingAndDesign;
