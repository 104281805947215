import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import { submitContactForm } from "../../services";
import Telegram_QR from "../../assests/images/telegram_QR.png";
import WhatsApp_QR from "../../assests/images/WhatsApp_QR.png";
import phoneIcon from "../../assests/images/phoneIcon.png";
import { useHistory } from 'react-router-dom';

function HeroComponent(props) {
    const {
        bigText,
        smallText,
        buttonText,
        page,
    } = props;

  const history = useHistory();
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const handleShowToast = (message) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);  
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactFormFields({
            ...contactFormFields,
            [name]: value,
        });
    };

    const submitFormHandler = async (e) => {
        e.preventDefault();
    
        const payload = {
          fields: [
            {
              name: "email",
              value: contactFormFields.email,
            },
            {
              name: "name",
              value: contactFormFields.name,
            },
            {
              name: "phone",
              value: contactFormFields.phone,
            },
            {
              name: "projectdescription",
              value: contactFormFields.projectdescription,
            },
          ],
        };
        const res = await submitContactForm(payload);
        if (res?.inlineMessage === "Thanks for submitting the form.") {
          setContactFormFields({
            name: "",
            email: "",
            phone: "",
            projectdescription: "",
          });
          handleShowToast();
          setToastMessage("We'll get back to you soon!");
          history.push("/thankyou-page");
        } else {
          handleShowToast();
          setToastMessage("Some error occurred. Please try again !");
        }
      };

    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: true,
        cssEase: 'linear',
        fadeSpeed: "5000"
    };
    return (
        <>
            {page === "landing-page" ? (

                <Container className="hero-container-landing-page-bg hero-container">
                    <div className="hero-container-inner">
                        <div className="landing-page-hero-section">
                            <p className="lp-big-text">Unleash Your Digital</p>
                            <p className="lp-big-text mt--15">Dreams with <span className="red">Digital Crew</span></p>
                            <br />
                            <span className="lp-small-text mt--15">Transform Your Ideas with Our Expert Solutions</span>
                            <div className="lp-qr-images">
                                <img className="lp-qr-image" src={Telegram_QR} alt="" />
                                <div className="center">
                                    <img className="lp-phone-image" src={phoneIcon} alt="" />
                                    <p className="lp-small-text contact-us-text-margin">CONTACT US</p>
                                </div>
                                <img className="lp-qr-image" src={WhatsApp_QR} alt="" />
                            </div>
                        </div>
                        <div className="lp-contact-form">
                            <h2>Contact Us</h2>
                            <p>Get Response Within 24 Hours</p>
                            <form onSubmit={submitFormHandler}>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter Your Name"
                                    value={contactFormFields.name}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="text"
                                    name="mobile"
                                    placeholder="Enter Mobile Number"
                                    value={contactFormFields.mobile}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter Your Email"
                                    value={contactFormFields.email}
                                    onChange={handleChange}
                                    required
                                />
                                <textarea
                                    name="projectdescription"
                                    placeholder="Write Your Message"
                                    value={contactFormFields.projectdescription}
                                    onChange={handleChange}
                                    required
                                />
                                <button type="submit">SUBMIT</button>
                            </form>
                        </div>
                    </div>
                </Container>
            ) :
                page === "thankyou-page" ? (
                    <Container className={`hero-container-${page}-bg hero-container`}>

                    </Container>
                ) :

                    (
                        <Container className={`hero-container-${page}-bg hero-container`}>
                            {
                                page === "home" &&
                                (<div className="pic-wrapper">
                                    <figure className="pic-1"></figure>
                                    <figure className="pic-2"></figure>
                                    <figure className="pic-3"></figure>
                                    <figure className="pic-4"></figure>
                                    <figure className="pic-5"></figure>
                                    <figure className="pic-6"></figure>
                                </div>)
                            }
                            <div className="hero-container-inner">
                                <div className="hero-section1">
                                    <div className="section-1-inner">
                                        <span className="hero-bigText" style={{ textAlign: page === "home" && "left" }} dangerouslySetInnerHTML={{ __html: bigText }}></span>
                                        {
                                            page === "portfolio" ? (<>
                                                <div className="portfolio-hero-section">
                                                    <div className="info-section">
                                                        <p className="big-number">10+</p>
                                                        <p className="red-text">Mobile Apps</p>
                                                    </div>
                                                    <div className="info-section">
                                                        <p className="big-number">81+</p>
                                                        <p className="red-text">Web Apps</p>
                                                    </div>
                                                    <div className="info-section">
                                                        <p className="big-number">21+</p>
                                                        <p className="red-text">Services</p>
                                                    </div>
                                                </div>
                                            </>)
                                                :
                                                page === "aboutus" ?
                                                    (<>
                                                        <p className="hero-smallText" dangerouslySetInnerHTML={{ __html: smallText }}></p>
                                                    </>)
                                                    :
                                                    page === "games" ?
                                                        (<>
                                                            <p className="hero-smallText" dangerouslySetInnerHTML={{ __html: smallText }}></p>
                                                        </>)
                                                        :
                                                        (<>
                                                            <p className="hero-smallText" dangerouslySetInnerHTML={{ __html: smallText }}></p>
                                                            <Button className={page === "home" ? "hero-button-red-to-grey" : "hero-button-grey-to-red"}><a href="/contact-us">{buttonText}{" "} {page === "home" && <i className="icon play-btn" />}</a></Button>
                                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </Container>
                    )}

        </>
    );
}

export default HeroComponent;