import React, { useEffect, useState } from "react";
import HeroComponent from "../../components/Hero";
import Slider from "react-slick";
import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import HashtagLogo from "../../assests/images/hashtagLogo.png";
import ProductGrowthIcon1 from "../../assests/images/ProductGrowthIcon1.png";
import ProductGrowthIcon2 from "../../assests/images/ProductGrowthIcon2.png";
import ProductGrowthIcon3 from "../../assests/images/ProductGrowthIcon3.png";
import ProductGrowthIcon4 from "../../assests/images/ProductGrowthIcon4.png";
import ProductGrowthIcon5 from "../../assests/images/ProductGrowthIcon5.png";
import ProductGrowthIcon6 from "../../assests/images/ProductGrowthSliderIconNew.png";
import CustomToast from "../../components/Toast";
import Fade from "react-reveal/Fade";
import { submitContactForm } from "../../services";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";
function ProductGrowth() {
  var settings = {
    dots: false,
    infinite: false,

    slidesToShow: 6,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: ProductGrowthIcon1,
      name: "On Device<br />Testing",
      id: 1,
    },
    {
      icon: ProductGrowthIcon2,
      name: "Automated Testing",
      id: 2,
    },
    {
      icon: ProductGrowthIcon3,
      name: "Visual QA",
      id: 3,
    },
    {
      icon: ProductGrowthIcon4,
      name: "User Acceptance<br />Testing",
      id: 4,
    },
    {
      icon: ProductGrowthIcon5,
      name: "QA<br />Consulting",
      id: 5,
    },
    {
      icon: ProductGrowthIcon6,
      name: "Agile Testing <br/>Process",
      id: 6,
    },
  ];

  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };
  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <SEO
        title="Product Growth - Digital Crew"
        description="At Digital Crew India, we involve product growth management and monitor your audience engagement to solve practical customer problems."
        url="https://digitalcrew.co.in/services/product-growth"
        keywords="automated testing, qa services, mobile app testing, manual testing services, mobile friendly test"
      />
      {/* <HelmetComponent title={"Product Growth - Digital Crew"} description={"Product engineering & digital solutions for startups and enterprises. Get in touch with the best product development company to develop a new product or the existing one."} url="services/product-growth" />   */}

      <HeroComponent
        bigText={"Building Better Products Starts Here"}
        smallText={
          "Our software development services are built to evolve your business idea into a successful growth story."
        }
        buttonText={"Book Our Demo"}
        page={"services-product-growth"}
      ></HeroComponent>
      <div className="product-growth-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/services">Services</a>
            <span className="active"> &gt; Product Growth</span>
          </p>
        </div>

        <div className="section2">
          <p className="black-heading">
            To accelerate your business quality, Digital Crew has QA services to
            increase
            <br />
            your business’s scalability.{" "}
          </p>
          <p className="white-heading">
            We have a range of QA services to keep an eye on your product growth
            and to assure
            <br />
            the quality and flexibility of your product for a secure brand.
          </p>
          <div className="center-box">
            <Slider {...settings} className="slider-styles">
              {sliderItemsArray.map((item) => {
                return (
                  <>
                    <div className="item-box" id={item.id}>
                      <div className="whitebox">
                        <img src={item.icon} alt="mobile app development"></img>
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
                    </div>
                  </>
                );
              })}
              <div className=""></div>
            </Slider>
          </div>
        </div>
        <div className="section3">
          <h2 className="mid-title">
            If QA testing is done properly = Risk-free scalable product
          </h2>
          <p className="midsmalltext">
            Team Digital Crew understands that your applications, software, and
            websites should provide value to end-users. We believe that creating
            a bug-free, fully-functional product, can only be achieved by
            comprehensive mobile app quality assurance (QA). Digital Crew offers
            full mobile app QA services as per your product’s requirement
            irrespective of the size of your business.
          </p>
          <div className="mid-section">
            <Row>
              <Col lg={5}>
                <div className="mid-box">
                  We offer you a range of facilities to check on your app and
                  website qualities for exponential growth. Our highly skilled
                  team perform robust mobile app testing that includes manual
                  testing, visual QA, and automated testing to ensure that your
                  application works smoothly and engagement is better to convert
                  visitors into customers.
                </div>
              </Col>

              <Col lg={5}>
                <div className="mid-box">
                  Some of the mainly used testing services like On-Device
                  Localization where our trained testers review your
                  translations on an actual mobile device to ensure zero broken
                  links and user interface issues. Automate mobile app testing
                  is more effective than a manual testing service. Automated
                  mobile app testing service ensure that new product versions do
                  not hinder functionality or create new bugs.
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="section4">
          <p className="mid-title">Our trusted patterned companies</p>
          <div className="sponsers">
            <Image src={Aws} alt="mobile app development" />
            <Image src={DigitalOcean} alt="mobile app development company" />
            <Image src={HashtagLogo} alt="mobile app development Solution" />
            <Image src={Cloudera} alt="mobile app development Solution" />
            <Image src={Googledev} alt="mobile app development Solution" />
          </div>
        </div>

        <div className="contact-section">
          <p className="midtitle">
            Share your estimate and custom plan for your business
          </p>
          <p className="midsmalltext">
            Our expert helps you with the best solutions for product growth.
          </p>
          <div className="contact-box">
            <Row>
              <Col lg={8}>
                <form
                  encType="multipart/form-data"
                  onSubmit={submitFormHandler}
                >
                  <div className="contact-form">
                    <input
                      className="contact-input"
                      type={"text"}
                      placeholder="Full Name"
                      name={"name"}
                      onChange={handleChange}
                      value={contactFormFields.name}
                    />
                    <Row>
                      <Col lg={6}>
                        <input
                          className="contact-input contact-number"
                          type={"text"}
                          placeholder="Contact Number"
                          name={"phone"}
                          onChange={handleChange}
                          value={contactFormFields.phone}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <input
                          className="contact-input"
                          type={"text"}
                          placeholder="Email Address"
                          name={"email"}
                          onChange={handleChange}
                          value={contactFormFields.email}
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="textarea"
                      placeholder="Project Description"
                      name={"projectdescription"}
                      onChange={handleChange}
                      value={contactFormFields.projectdescription}
                    ></textarea>
                    <Row className="bottom-row">
                      {/*  <Col lg={6}>
                                            <div className='file-div'>
                                                <input className='contact-input-file' type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.docx" placeholder="" name="file" onChange={handleChange} />
                                                <Button className='btn btn-file'>+</Button>
                                                {contactFormFields.file !== null && <p>{contactFormFields.file?.name}</p>}
                                            </div>

                                            <p>Attached File Less then 25 mB</p>
                                        </Col> */}
                      <Col lg={12}>
                        <Button className="btn contact-form-btn" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </Col>
              <Col lg={4}>
                <div className="right-side-inner">
                  <Fade bottom>
                    <p className="bigtext">
                      Let's Get into
                      <br />
                      <span className="red-text">Business discussions </span> !!
                    </p>
                    <p className="smallText">
                      Our strength lies in the comprehensive suite of services
                      we offer to our clients.
                    </p>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext bottom">
            We’d like to hear from you and will be happy to answer your queries
          </p>
        </div>
      </div>
      <CustomToast
        show={showToast}
        onClose={handleHideToast}
        message={toastMessage}
      ></CustomToast>
    </>
  );
}

export default ProductGrowth;
