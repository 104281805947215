import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import HeroComponent from "../../components/Hero";
import smallWhiteCircle from "../../assests/images/smallWhiteCircle.png";
import healthcareImage1 from "../../assests/images/healthcareImage1.png";
import healthcareTextImage from "../../assests/images/healthcareTextImage.png";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";

function Healthcare() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
   
      {/* <HelmetComponent
        title={"Healthcare Software Development Services - Digital Crew"}
        description={
          "Digitalized all your healthcare management services with Digital Crew. Digital Crew deliver world class healthcare application development services to improve medical care."
        }
        url="services/healthcare"
      /> */}
      <SEO
        title="Healthcare App Development Company | Medical App Development Services"
        description="We are the best Healthcare app development company that provides top medical app development solutions with the help of our expert Healthcare App Developers."
        url="https://digitalcrew.co.in/industries/healthcare"
      />
      <div>
        <HeroComponent
          bigText={
            "Digitalized all your healthcare management services with Digital Crew"
          }
          smallText={
            "Create, collaborate, and organize all your healthcare work with healthcare software development services."
          }
          buttonText={"Talk to our Experts"}
          page={"HealthcareHomeBanner"}
        ></HeroComponent>
        <div className="healthcare-section">
          <div className="section-breadcrumb">
            <p className="breadcrumb">
              <a href="/industries">Industries</a>
              <span className="active"> &gt; Healthcare</span>
            </p>
          </div>
          <div className="section1">
            <Row>
              <Col lg={4} md={4} className="left-side-fintech">
                <img
                  src={healthcareImage1}
                  alt="Healthcare App Development Company"
                ></img>
              </Col>
              <Col lg={8} md={8} className="right-side-fintech">
                <p className="heading">
                  A complete solution to manage your medical practice
                </p>
                <p className="para">
                  Digital Crew is known for its seamless AI and ML technology
                  development service. With this, Natural Language Processing
                  (NLP), Natural language Generation (NLG), Python Machine
                  Learning, TensorFlow Models serving Machine learning, Deep
                  learning solutions, development solutions of machine learning
                  technologies, and artificial intelligence web solutions. With
                  a team of professional AI and ML developers, we work hard to
                  achieve your dream project with 100% accuracy, client
                  satisfaction, and on-time delivery.
                  <br />
                  <br />
                  Digital Crew offers mind-blowing healthcare app development
                  services to improve medical care. We have years of expertise
                  in healthcare app development and are leading in the fitness
                  and healthcare service segment.
                  <br />
                  <br />
                  We build scalable hospital and clinic management software
                  systems that enable you to process your core financial,
                  clinical, and operational processes smoothly. Our
                  professionals provide fast paced healthcare ecosystem with the
                  latest digital solutions.
                  <br />
                  <br />A custom healthcare software development service from
                  clinical management to patient treatment and diagnosis also.
                  Our technology helps to boost your healthcare system by
                  managing everything perfectly. We have been working on many
                  digitization projects that address three main challenges to
                  accomplishing healthcare digitization: governance,
                  organisational structure and mindset. We help you with custom
                  services as per the requirements and we are fully equipped to
                  build and complete your health care project.
                </p>
              </Col>
            </Row>
          </div>

          <div className="section2">
            <Row>
              <Col lg={3} md={4} className="left-side-fintech">
                <img
                  src={healthcareTextImage}
                  alt="Healthcare App Development"
                ></img>
              </Col>
              <Col lg={9} md={8} className="right-side-fintech">
                <p className="para">
                  Let's connect with Digital Crew's experts to build a digitised
                  healthcare system: Our healthcare products will help improve
                  your healthcare business's efficiency and lower cost. We
                  specialise in providing cutting-edge custom healthcare mobile
                  app development services that drive innovation and introduce
                  transformative approaches to the healthcare industry. To
                  create a revolutionary change in your healthcare management
                  system, our experts integrate most of the latest technology to
                  build a suitable platform for your administration with
                  post-maintenance service.
                </p>
              </Col>
            </Row>
          </div>

          <div className="section3">
            <div className="text-section">
              <div className="upper-section">
                <i className="icon healthcareIcon1" />
                Patient Administration
              </div>
              <div className="lower-section">
                Now you can easily manage your administration activities like
                Appointment Scheduling (Doctor, Test, Service, Surgery, Generic
                Resource), Patient Registration, ADT (Admission, Discharge and
                Transfer), Bed Management/Bed View and Patient Communication
                with advance healthcare software development service.
              </div>
            </div>
            <div className="text-section">
              <div className="upper-section">
                <i className="icon healthcareIcon2" />
                Revenue Cycle Management
              </div>
              <div className="lower-section">
                By using the best of technology our professional can save you
                time by introducing an automation process for Cash, Credits,
                Billing, Packages, Rate/Discount Management, Credit Notes,
                Deposits, Claim Submissions, Medical Coding, Claim
                Reconciliation and Remittance Advice and many more.{" "}
              </div>
            </div>
            <div className="text-section">
              <div className="upper-section">
                <i className="icon healthcareIcon3" />
                Clinical Features
              </div>
              <div className="lower-section">
                Digital Crew believe in innovation, we have integrated
                Outpatient EMR (with configurable form designs to capture SOAP
                notes, diagnosis and e-prescriptions), Pending Prescriptions
                Dashboard, Inpatient EMR (Notes, MAR, Clinical Documentation),
                OT-EMR and the EMR View of consolidated electronic medical
                records for better healthcare management services.
              </div>
            </div>
            <div className="text-section">
              <div className="upper-section">
                <i className="icon healthcareIcon4" />
                Pharmacy/Inventory
              </div>
              <div className="lower-section">
                We craft innovative solutions and deliver incomparable results
                to manage every step of Sales, Procurement, and Stock Management
                (Adjustments, Transfers, Indenting) to maintain the flow of the
                operating system by managing all the aspects of the business.
              </div>
            </div>
            <div className="text-section">
              <div className="upper-section">
                <i className="icon healthcareIcon5" />
                Reporting and other add-on modules
              </div>
              <div className="lower-section">
                We understand how important it is for an organization to keep an
                eye on every particular detail, and manage that accordingly for
                business growth. Our experts implement innovative digital
                healthcare solutions and strategies. Daily activities like
                online appointments, connecting with the doctors on Video calls,
                digital health records, period trackers, pregnancy trackers,
                diet personalizer and medicine alarms, etc are our few highly
                featured tools to make our service better and advanced.
              </div>
            </div>
          </div>
          <div className="section4">
            <p className="heading">Tech Stack</p>
            <div className="redbox">
              <div className="list-div">
                <p className="list-heading">Front &amp; Back end</p>
                <p className="list-item">React JS</p>
                <p className="list-item">Angular JS</p>
                <p className="list-item">Node JS</p>
                <p className="list-item">Spring Boot</p>
                <p className="list-item">Unity 3D</p>
                <p className="list-item">Bootstrap/ tailwind css</p>
              </div>
              <div className="list-div">
                <p className="list-heading">DevOps tools</p>
                <p className="list-item">AWS</p>
                <p className="list-item">Docker</p>
                <p className="list-item">Kovenates</p>
                <p className="list-item">Jenkins</p>
                <p className="list-item">Git</p>
              </div>
              <div className="list-div">
                <p className="list-heading">QA tools</p>
                <p className="list-item">Cypress</p>
                <p className="list-item">Selenium</p>
                <p className="list-item">JMeter</p>
              </div>
              <div className="list-div">
                <p className="list-heading">Mobile</p>
                <p className="list-item">React Native</p>
                <p className="list-item">Flutter</p>
                <p className="list-item">Unity 3D</p>
                <p className="list-item">Ionic</p>
                <p className="list-item">Swift</p>
                <p className="list-item">Android Studio</p>
              </div>
            </div>
          </div>
          <div className="section5">
            <p className="heading">
              Why choose <span>Digital Crew</span> is best for custom medical
              software development ?
            </p>
            <p className="para">
              Our highly dedicated team believe in innovation, we are a team of
              enthusiastic people. We provide services from prototyping to
              design, development, and, deployment of healthcare software
              development. We help to build healthcare applications that can
              improve patient care and management system just by delivering the
              right solution as per the organisations’ demands. It’s time to
              choose the right fit custom healthcare management system software
              development company for your medical business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Healthcare;
