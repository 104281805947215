import React, { useEffect, useState } from "react";
import HeroComponent from "../components/Hero";
import SEO from "../components/SEO";

import Telegram_QR from "../assests/images/telegram_QR.png";
import WhatsApp_QR from "../assests/images/WhatsApp_QR.png";
import halfImage from "../assests/images/two-content-business-partners-discussing-issue 1.png";

function ThankyouPage() {


    return (
        <>
            <SEO
                title="Thank You - Digital Crew"
                description="Thank you for reaching out to Digital Crew. We appreciate your interest and will get back to you within 24 hours. Stay tuned for our expert solutions."
                url="https://digitalcrew.co.in/thankyou-page"
            />

            <HeroComponent
                bigText={""}
                smallText={""}
                buttonText={""}
                page={"thankyou-page"}
            />

            <div className="home-section2 thanks-bg-container">
                <div className="section2-upper thanks-bg-text">
                    <p className="midText pt-100">Your Inquiry is <span className="red">Important to Us</span></p>
                    <p className="midSmallText mt-50">
                        Thank you for contacting Digital Crew. We appreciate you taking the time to reach out and express your interest in our services. Our team is dedicated to providing exceptional service and innovative solutions tailored to your needs.
                    </p>
                </div>
            </div>
            {/* <div className="section2-lower">
                <div className="thanks-dev-container">

                    <div className="thanks-card">
                        <div className="thanks-text-container">
                            <h1 className="thanks-title">Need Immediate Assistance?</h1>
                            <p className="thanks-subtitle">If you require immediate assistance, please write to us on Telegram, WhatsApp or Call us now!</p>
                            <p className="thanks-contact"><span className="thanks-icon phone-icon"></span>+91-83199-10098</p>
                            <p className="thanks-contact"><span className="thanks-icon email-icon"></span>connect@digitalcrew.co.in</p>
                            <div className="thanks-qr-container">
                                <div className="thanks-qr">
                                    <img src={Telegram_QR} alt="Telegram QR Code" />
                                    <p>@Digital_Crew_IndiaGames</p>
                                </div>
                                <div className="thanks-qr">
                                    <img src={WhatsApp_QR} alt="WhatsApp QR Code" />
                                    <p>https://wa.me/message/xgs75uba53h3l1</p>
                                </div>
                            </div>
                        </div>

                        <div className="thanks-bg-image">
                            <img src={halfImage} alt="image" />
                        </div>
                    </div>


                    <div>

                        <p className="midSmallText mt-50">
                            Thank you once again for considering Digital Crew.<br />
                            We look forward to helping you achieve your business goals!
                        </p>
                    </div>
                </div>
            </div> */}

            <div className="section2-lower">
                <div className="thanks-dev-container">
                    <div className="thanks-card">
                        <div className="thanks-text-container">
                            <h1 className="thanks-title">Need Immediate Assistance?</h1>
                            <p className="thanks-subtitle">If you require immediate assistance, please write to us on Telegram, WhatsApp or Call us now!</p>
                            <p className="thanks-contact"><span className="thanks-icon phone-icon"></span>+91-83199-10098</p>
                            <p className="thanks-contact"><span className="thanks-icon email-icon"></span>connect@digitalcrew.co.in</p>
                            <div className="thanks-qr-container">
                                <div className="thanks-qr">
                                    <img src={Telegram_QR} alt="Telegram QR Code" />
                                    <div className="thanks-qr-text">
                                        <span className="blue">Telegram</span><br />
                                        <a href="tg://resolve?domain=Digital_Crew_IndiaGames" target="_blank" rel="noopener noreferrer">@Digital_Crew_IndiaGames</a>
                                    </div>
                                </div>
                                <div className="thanks-qr">
                                    <img src={WhatsApp_QR} alt="WhatsApp QR Code" />
                                    <div className="thanks-qr-text">
                                        <span className="green">WhatsApp</span><br />
                                        <a href="https://wa.me/message/xgs75uba53h3l1" target="_blank" rel="noopener noreferrer">https://wa.me/message/xgs75uba53h3l1</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="thanks-bg-image">
                            <img src={halfImage} alt="image" />
                        </div>
                    </div>
                    <div>
                        <p className="midSmallText mt-50">
                            Thank you once again for considering Digital Crew.<br />
                            We look forward to helping you achieve your business goals!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ThankyouPage;
