import React from 'react'
import { useEffect } from 'react';
import HeroComponent from '../components/Hero'
import BirdAndNest from '../assests/images/birdandnest.png'
import HelmetComponent from '../components/Helmet';

function My404Component() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  
    return (
    <>
            <HelmetComponent title={"404 Page Not Found"} description={"404 Page Not Found"} />  
            <HeroComponent
                bigText={"Get A Properly Researched Product Development For Your Domain."}
                smallText={"Let’s connect to the professional research and innovation team."}
                buttonText={"Book Our Demo"}
                page={"services-research"}
            ></HeroComponent>
            <div className="research-and-innovative-section">
            
                <div className="section3">
                    <h2 className="mid-title">
                        <span className="redblock">Sorry,</span> The Page You Are Looking<br />
                        For Is Not Available.
                    </h2>
                    <p className="midsmalltext">
                        The link you followed may be broken, or the page may have been removed.<br/>
                        Go back to <a href='/' className="hvr-grow">homepage</a>
                         </p>
                    <div className="mid-section">
                       <img src={BirdAndNest} alt="Bird-and-nest" />
                    </div>
                </div>
                <div className='contact-section'>
                    <p className='midtitle'>Get in touch</p>
                    <p className='midsmalltext bottom'>We’d like to hear from you and will be happy to answer your queries</p>
                </div>
            </div>
    </>
  )
}

export default My404Component