import React from 'react'
import { Helmet } from 'react-helmet';
const seo = require('../../seo/seo')

function HelmetComponent(props) {
    const {
        title,
        description,
        url
    } = props;
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:description"   data-react-helmet="true"  content={description ? description : ''}  />
                <meta property="og:url"  data-react-helmet="true"  content={`https://digitalcrew.co.in/${url ? url : ''}`} />
                <link rel="canonical" href={`https://digitalcrew.co.in/${url ? url : ''}`} />
            </Helmet>
        </>
    )
}

export default HelmetComponent