import React, { useState } from "react";
import "./index.css";
import SEO from "../../components/SEO";
import HeroComponent from "../../components/Hero";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Fade, Zoom } from "react-reveal";
import Slider from "react-slick";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import Sap from "../../assests/images/sap.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import Proddev from "../../assests/images/prod-dev.png";
import Ourtech from "../../assests/images/our-tech.png";
import homeSliderImage1 from "../../assests/images/homeSliderImage1.png";
import homeSliderImage2 from "../../assests/images/homeSliderImage2.png";
import homeSliderImage3 from "../../assests/images/homeSliderImage3.png";
import homeSliderImage4 from "../../assests/images/homeSliderImage4.png";
import homeSliderImage5 from "../../assests/images/homeSliderImage5.png";
import homeSliderImage6 from "../../assests/images/homeSliderImage6.png";
import homeSliderImage7 from "../../assests/images/homeSliderImage7.png";
import { submitContactForm, uploadFile } from "../../services";
import CustomToast from "../../components/Toast";
import TBLogo from "../../assests/images/TBLogo.png";
import AppFutura from "../../assests/images/appfutura-badge.jpg";
import HelmetComponent from "../../components/Helmet";
import { useHistory } from 'react-router-dom';

import diamondLudo from "../../assests/images/Diamond_Ludo.png";
import kheloAdda from "../../assests/images/Khelo_Adda.png";
import DCpool from "../../assests/images/DC_Pool.png";
import kwelibuy from "../../assests/images/Kwelibuy.png";
import realLudo from "../../assests/images/Ludo_Real.png";
import winNow from "../../assests/images/Win_Now.png";
import DCrummy from "../../assests/images/DC_Rummy.png";
import pocket7 from "../../assests/images/Pocket_7.png";
import eziVote from "../../assests/images/EZI_Vote.png";
import wingsPay from "../../assests/images/Wing_Pay.png";
import betcricks from "../../assests/images/BetCricks.png";

import gameIcon from "../../assests/images/Group.png";
import softwareIcon from "../../assests/images/Vector.png";
import productIcon from "../../assests/images/Vector (1).png";
import devGameIcon from "../../assests/images/dev-game-icon.png";
import devDesktopIcon from "../../assests/images/dev-desktop-icon.png";
import DevProductIcon from "../../assests/images/dev-product-icon.png";
import callIcon from "../../assests/images/Call.svg";
import circleImg_1 from "../../assests/images/Group 28.png";
import circleImg_2 from "../../assests/images/Group 26.png";
import circleImg_3 from "../../assests/images/Group 27.png";
import ourProcess from "../../assests/images/processDesign.png";

import whyUsImg1 from "../../assests/images/image 14.png";
import whyUsImg2 from "../../assests/images/image 15.png";
import whyUsImg3 from "../../assests/images/image 16.png";
import whyUsImg4 from "../../assests/images/image 17.png";
import whyUsImg5 from "../../assests/images/image 18.png";
import whyUsImg6 from "../../assests/images/image 19.png";
import whyUsImg7 from "../../assests/images/image 20.png";
import whyUsImg8 from "../../assests/images/image 21.png";
import whyUsImg9 from "../../assests/images/image 22.png";

const LandingPage = () => {
  const history = useHistory();
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    mobile: "",
    email: "",
    projectdescription: "",
  });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [activeTab, setActiveTab] = useState('expertise');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactFormFields({
      ...contactFormFields,
      [name]: value,
    });
  };

  const handleContactUs = () => {
    history.push("/contact-us");
  }

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
      history.push("/thankyou-page");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", contactFormFields);
  };

  let features = [
    {
      icon: "150+",
      name: "Projects",
      marginTop: false,
    },
    {
      icon: "70+",
      name: "Mobile App",
      marginTop: true,
    },
    {
      icon: "40+",
      name: "Blockchain",
      marginTop: false,
    },
    {
      icon: "50+",
      name: "Happy Clients",
      marginTop: true,
    },
    {
      icon: "70+",
      name: "Expert Teams",
      marginTop: false,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 648,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: homeSliderImage1,
      name: "Noah William",
      id: 1,
      para: "We would like to express our satisfaction in the cooperation regarding the development of our Poker and Rummy Games applications. George and the development team did a very professional job. We are satisfied with the solutions given to us and with the communication flow through the project.",
    },
    {
      icon: homeSliderImage2,
      name: "Joyce Barret",
      id: 2,
      para: "Digital Crew has delivered great results on time. We have worked with them for developing Sports Betting software. All through the working tenure, we found their team energetic and highly professional in working. Looking forward to working with them again",
    },
    {
      icon: homeSliderImage3,
      name: "Richard Samuel",
      id: 3,
      para: "We would like to recommend the Digital Crew development team. We have got solutions from them in iGaming; They have capabilities to handle multiple projects in Card Gaming, Fantasy Sports, Sports Book and got the games delivered to our company on time. We look forward to working with them in future projects",
    },
    {
      icon: homeSliderImage4,
      name: "Tim Larson",
      id: 4,
      para: "Job well done and I hope we can continue to grow together. Thank you for the services your team has provided. Your team has been a pleasure to work with, professional and timely.",
    },
    {
      icon: homeSliderImage5,
      name: "Kyle James",
      id: 4,
      para: 'The Digital Crew is always available, extremely knowledgeable, and have a tremendous "can-do" attitude. We have worked on a Sports Book with them and they provided the best solutions.',
    },
    {
      icon: homeSliderImage6,
      name: "Benjamin Liam",
      id: 4,
      para: "I would like to thank Digital Crew and George for all the efforts and suggestions that assisted us in providing Blockchain solutions to us in the area of web, technology.",
    },
    {
      icon: homeSliderImage7,
      name: "Oli John",
      id: 4,
      para: "I worked with George and team Digital Crew for several years on various projects. Unflinchingly reliable, professional and proactive from a technology and security standpoint.",
    },
  ];





  return (
    <>
    <SEO
  title="Welcome to Digital Crew - Transform Your Ideas with Us"
  description="Explore Digital Crew's innovative solutions in Mobile Apps, Software, Web Designing, and Development. Discover how we can bring your digital dreams to life."
  url="https://digitalcrew.co.in/landing-page"
/>
      <HeroComponent
        bigText={
          "<span className='redQuotes'>“</span>Unleash Your Digital<b/> Dreams with Digital Crew<span className='redQuotes'>”</span>"
        }
        smallText={
          "Transform Your Game Idea with Our Expert Solutions."
        }
        buttonText={"Talk with the Experts"}
        page={"landing-page"}
      ></HeroComponent>

      <div className="home-section2 bg-container">
        <div className="section2-upper">
          <p className="midText">Introduction</p>
          <p className="midSmallText">
            Digital Crew, your partner in advanced software solutions!<br /> With years of expertise, we specialize in delivering tailored software that drives efficiency and innovation.<br /> Trust us to bring your vision to life with precision and excellence.
          </p>
          <br />
          <br />
        </div>
        <div className="section2-lower">
          <Fade bottom>
            <div className="mid-title">
              <p>Our Clients</p>
            </div>
          </Fade>
          <div className="clients">
            <Image src={diamondLudo} alt="Diamond Ludo" width="100%" height="100%" />
            <Image src={kheloAdda} alt="Kheloadda" width="100%" height="100%" />
            <Image src={DCpool} alt="DC Pool" width="100%" height="100%" />
            <Image src={kwelibuy} alt="Kwelibuy" width="100%" height="100%" />
            <Image src={realLudo} alt="Real Ludo" width="100%" height="100%" />
            <Image src={winNow} alt="Win Now" width="100%" height="100%" />
            <Image src={DCrummy} alt="DC Rummy" width="100%" height="100%" />
            <Image src={pocket7} alt="Pocket 7" width="100%" height="100%" />
            <Image src={eziVote} alt="Ezivote" width="100%" height="100%" />
            <Image src={wingsPay} alt="Wings Pay" width="100%" height="100%" />
            {/* <a
              href="https://techbehemoths.com/company/digital-crew-2 "
              target="_blank"
            > */}
            <Image src={betcricks} alt="Betcrciks" width="100%" height="100%" />
            {/* </a> */}
          </div>
        </div>
      </div>

      <div className="home-section2">
        <div className="section2-upper">
          <p className="midText">About Us</p><br />
          <p className="midText">Your Partner in <span className="red">Digital Success</span></p><br />
          <p className="midSmallText">
            Digital Crew is your trusted guide in the Digital World. From iGaming to customized Software development, We help you navigate the ever-changing online landscape with expertise and care. We have a team of experts who are available to assist in:
          </p>



          <div className="about-us-container">
            <div className="about-us-card">
              <div className="image-heading-wrapper">
                <div className="img-box">
                  <img src={gameIcon} alt="Create Engaging Games" />
                </div>
                <div className="heading">Create Engaging Games</div>
              </div>
              <div className="text-box">
                <p>
                  We design and build games that grab people's attention and keep them interested. Our games are memorable and fun, making a lasting impact on players.
                </p>
              </div>
            </div>
            <div className="about-us-card-red">
              <div className="image-heading-wrapper">
                <div className="img-box">
                  <img src={softwareIcon} alt="Software That Fits Your Business" />
                </div>
                <div className="heading">Software That Fits Your Business</div>
              </div>
              <div className="text-box">
                <p>
                  We create custom software that meets your specific needs and helps you achieve your goals. Our software is designed to work perfectly for your business.
                </p>
              </div>
            </div>
            <div className="about-us-card">
              <div className="image-heading-wrapper">
                <div className="img-box">
                  <img src={productIcon} alt="Create New And Exciting Products" />
                </div>
                <div className="heading">Create New And Exciting Products</div>
              </div>
              <div className="text-box">
                <p>
                  We help businesses grow by creating new and innovative products that stand out from the rest. Our team of experts will be with you every step of the way, guiding and supporting you.
                </p>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className="section2-lower">
          <Fade bottom>
            <div className="mid-title mt-50">
              <p>Our Development Services</p>
            </div>
          </Fade>
          <div className="dev-container">
            <div className="dev-section">
              <img className="dev-img" src={devGameIcon} alt="Game Development" />
              <div className="dev-title">Game Development</div>
              <p>We create games that are enjoyable and stay with players long after they finish playing.</p>
            </div>
            <div className="dev-section">
              <img className="dev-img" src={devDesktopIcon} alt="Software Development" />
              <div className="dev-title">Software Development</div>
              <p>We create custom software that simplifies tasks, saves time, and helps businesses work more efficiently.</p>
            </div>
            <div className="dev-section">
              <img className="dev-img" src={DevProductIcon} alt="Product Development" />
              <div className="dev-title">Product Development</div>
              <p>We help businesses create and launch new products that drive growth and success.</p>
            </div>
          </div>
          <div className="contact-us">
            <div className="contact-us-div" onClick={handleContactUs} style={{cursor:"pointer"}}>
              <img src={callIcon} alt="Call" />
              <p className="contact-us-text">Contact Us</p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section3">
        <Fade bottom>
          <div className="mid-title benefits-heading">
            <p>Benefits</p>
          </div>
        </Fade>
        <div className="overlap-div rightside">
          <div className="image left hvr-grow benefits-img-1">
            <Image src={circleImg_1} alt="mobile app development company" style={{ width: "100%", height: "100%" }} />
          </div>

          <Fade bottom>
            <div className="info-div">
              <p className="benefits-heading-1">
                Game Development <span className="red">Benefits</span>:
              </p>
              <div className="grey-container">
                <ul className="benefit-list benefits-text-1">
                  <li>We create attention-grabbing games that keep players interested</li>
                  <li>Our games are customized to fit your specific needs and goals</li>
                  <li>They work smoothly across various devices and platforms</li>
                  <li>Encourages users to play and share with others</li>
                  <li>Leads to increased engagement and revenue growth</li>
                  <li>Helps grow your business through successful game creation</li>
                </ul>
              </div>
            </div>
          </Fade>
        </div>
        <div className="overlap-div leftside">
          <div className="image left hvr-grow benefits-img-2">
            <Image src={circleImg_2} alt="mobile app development company" style={{ width: "100%", height: "100%" }} />
          </div>
          <Fade bottom>
            <div className="info-div">
              <p className="benefits-heading-2">Software Development <span className="red">Benefits</span>:</p>
              <div className="grey-container">
                <div className="list-items">
                  <ul className="benefit-list benefits-text-2">
                    <li>Software made just for your business</li>
                    <li>Automation makes work easier and faster</li>
                    <li>Easy-to-use software that’s simple to navigate</li>
                    <li>Software that grows with your business</li>
                    <li>Saves you money and increases profits</li>
                  </ul>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="overlap-div rightside">
          <div className="image left hvr-grow benefits-img-3">
            <Image src={circleImg_3} alt="mobile app development company" style={{ width: "100%", height: "100%" }} />
          </div>

          <Fade bottom>
            <div className="info-div">
              <p className="benefits-heading-3">
                Product Development <span className="red">Benefits</span>:
              </p>
              <div className="grey-container">
                <div className="list-items">
                  <ul className="benefit-list benefits-text-3">
                    <li>Creative product designs that help businesses grow</li>
                    <li>Quick creation and testing of new products</li>
                    <li>Products designed to be easy to use and understand</li>
                    <li>Products that make you special and stand out from others</li>
                    <li>Help with launching and rolling out new products</li>
                  </ul>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>

      <div class="why-us-container">
        <h1>Why Choose Digital Crew</h1>
        <div class="why-us-tabs">
          <div className="why-us-tabs">
            <button
              className={`why-us-tab-button ${activeTab === 'expertise' ? 'active' : ''}`}
              onClick={() => handleTabClick('expertise')}
              data-tab="expertise">
              Expertise
            </button>
            <button
              className={`why-us-tab-button ${activeTab === 'personalized' ? 'active' : ''}`}
              onClick={() => handleTabClick('personalized')}
              data-tab="personalized">
              Personalized Approach
            </button>
            <button
              className={`why-us-tab-button ${activeTab === 'results' ? 'active' : ''}`}
              onClick={() => handleTabClick('results')}
              data-tab="results">
              Results-Driven
            </button>
          </div>
        </div>
        <Zoom>
          <div className={`why-us-tab-content ${activeTab === 'expertise' ? 'active' : ''}`} id="expertise">
            <div class="why-us-card">
              <img src={whyUsImg1} alt="Tech Experts" />
              <h2>Tech Experts</h2>
              <p>Years of experience in combining technology and innovation</p>
            </div>
            <div class="why-us-card">
              <img src={whyUsImg2} alt="Digital Guides" />
              <h2>Digital Guides</h2>
              <p>In-depth knowledge & insights to the Digital World</p>
            </div>
            <div class="why-us-card">
              <img src={whyUsImg3} alt="Simplifying Things" />
              <h2>Simplifying Things</h2>
              <p>Making complex problems easy to solve</p>
            </div>
          </div>
          <div className={`why-us-tab-content ${activeTab === 'personalized' ? 'active' : ''}`} id="personalized">
            <div class="why-us-card">
              <img src={whyUsImg4} alt="Custom Success" />
              <h2>Custom Success</h2>
              <p>Creating personalized solutions for your business to thrive</p>
            </div>
            <div class="why-us-card">
              <img src={whyUsImg5} alt="Your Unique Solution" />
              <h2>Your Unique Solution</h2>
              <p>Carefully crafting solutions specifically that fit your business story</p>
            </div>
            <div class="why-us-card">
              <img src={whyUsImg6} alt="Perfect Fit" />
              <h2>Perfect Fit</h2>
              <p>Trust our expert team to bring precision, efficiency, and excellence to every project</p>
            </div>
          </div>
          <div className={`why-us-tab-content ${activeTab === 'results' ? 'active' : ''}`} id="results">
            <div class="why-us-card">
              <img src={whyUsImg7} alt="Grow Your Business" />
              <h2>Grow Your Business</h2>
              <p>Helping and guiding to achieve real results that move your business forward</p>
            </div>
            <div class="why-us-card">
              <img src={whyUsImg8} alt="Success You Can See" />
              <h2>Success You Can See</h2>
              <p>Delivering clear, measurable outcomes that drive success</p>
            </div>
            <div class="why-us-card">
              <img src={whyUsImg9} alt="Lasting Impact" />
              <h2>Lasting Impact</h2>
              <p>Exclusive solutions that make a difference for years to come</p>
            </div>
          </div>
        </Zoom>
      </div>

      <div className="home-section4">
        <Fade bottom>
          <p className="midtitle">Our Process</p>
        </Fade>
        <div class="our-process-container">
          <div class="our-process-image">
            <Zoom>
              <img src={ourProcess} alt="Process Design" />
            </Zoom>
          </div>
        </div>
      </div>

      <div className="home-section5">
        <Row>
          <Col lg={3} className="textside">
            <div className="text-block">
              <p className="heading">Testimonials</p>
              <p className="para">
                What clients
                <br />
                think of us !!
              </p>
            </div>
          </Col>
          <Col lg={9} className="sliderside">
            <Slider {...settings} className="slider-styles">
              {sliderItemsArray.map((item, index) => {
                return (
                  <>
                    <div className="item-box" id={item.id}>
                      <div className="whitebox">
                        <img src={item.icon} alt="software development" style={{ width: "100%", height: "100%" }} />
                      </div>
                      <i className="icon redDoubleQoutes"></i>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.name }}
                        className="name"
                      ></p>
                      <p className="para-style">{item.para}</p>
                    </div>
                  </>
                );
              })}
              <div className=""></div>
            </Slider>
          </Col>
        </Row>
      </div>

      <div className="contact-section">
        <div className="contact-box">
          <Row>
            <Col lg={8}>
              <form encType="multipart/form-data" onSubmit={submitFormHandler}>
                <div className="contact-form">
                  <input
                    className="contact-input"
                    type={"text"}
                    placeholder="Full Name"
                    name={"name"}
                    onChange={handleChange}
                    value={contactFormFields.name}
                  />
                  <Row>
                    <Col lg={6}>
                      <input
                        className="contact-input contact-number"
                        type={"text"}
                        placeholder="Contact Number"
                        name={"phone"}
                        onChange={handleChange}
                        value={contactFormFields.phone}
                      />
                    </Col>
                    <Col lg={6}>
                      {" "}
                      <input
                        className="contact-input"
                        type={"text"}
                        placeholder="Email Address"
                        name={"email"}
                        onChange={handleChange}
                        value={contactFormFields.email}
                      />
                    </Col>
                  </Row>
                  <textarea
                    className="textarea"
                    placeholder="Project Description"
                    name={"projectdescription"}
                    onChange={handleChange}
                    value={contactFormFields.projectdescription}
                  ></textarea>
                  <Row className="bottom-row">
                    <Col lg={12}>
                      <Button className="btn contact-form-btn" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </div>
              </form>
            </Col>
            <Col lg={4}>
              <div className="right-side-inner">
                <Fade bottom>
                  <p className="bigtext">
                    Let's Get into
                    <br />
                    <span className="red-text">Business discussions </span> !!
                  </p>
                  <p className="smallText">
                    Our strength lies in the comprehensive suite of services we
                    offer to our clients.
                  </p>
                </Fade>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
