import React from "react";
import { Accordion, Col, Container, Image, Row, Nav } from "react-bootstrap";
import DCLOGO from "../../assests/images/DClogo-footer.png";
import Linkedin from "../../assests/images/linkedInLogo.png";
import Twitter from "../../assests/images/Twitter.png";
import Facebook from "../../assests/images/Facebook.png";
import Whatsapp from "../../assests/images/whatsAppIcon.png";
import Skype from "../../assests/images/skypeIcon.png";
import { Link, useHistory } from "react-router-dom";

function Footer() {
  const history = useHistory();
  return (
    <>
      <Container className="footer-container">
        <Row className="top-row">
          <Col md={12} sm={12} lg={4} className="column-1">
            <Image src={DCLOGO} height="60px" width="235px"></Image>
            <div className="socials">
              <a
                href=" https://www.facebook.com/Digital-Crew-100914105418068"
                target="_blank"
              >
                <Image src={Facebook} width="100%" height="100%"></Image>
              </a>
              <a href="https://twitter.com/DigitalCrew3" target="_blank">
                <Image src={Twitter} width="100%" height="100%"></Image>
              </a>
              <a
                href="https://www.linkedin.com/company/digital-crew-india/?originalSubdomain=in"
                target="_blank"
              >
                <Image src={Linkedin} width="100%" height="100%"></Image>
              </a>

              <a href="https://wa.me/8319910098?text=" target="_blank">
                <Image src={Whatsapp} width="100%" height="100%"></Image>
              </a>
              <a
                href="https://msng.link/o/?digitalcrew_0721=sk"
                target="_blank"
              >
                <Image src={Skype} width="100%" height="100%"></Image>
              </a>

              {/* <a href="#">
                        <Image src={}></Image>
                    </a>
                    <a href="#">
                        <Image src={}></Image>
                    </a> */}
            </div>
            <p>
              Digital Crew is one of the most experienced and leading game app
              and software development companies in India. Our experts focus on
              research and innovation to carry out the newest trends and
              technologies to create an effective platform for your users. You
              can have complete Digital Solutions, Software development,
              Blockchain, iGaming and E-Commerce services under one roof.
            </p>
          </Col>
          <Col sm={12} className="mobile-view mobile-footer-links">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Services</Accordion.Header>
                <Accordion.Body>
                  <div className="links">
                    <Link rel="canonical" to="/services/software-development">
                      Software Development
                    </Link>
                    <Link rel="canonical" to="/industries/blockchain">
                      Blockchain Development{" "}
                    </Link>
                    <Link rel="canonical" to="/services/software-development">
                      Mobile Apps Development
                    </Link>
                    <Link rel="canonical" to="/industries/igaming">
                      iGaming Development{" "}
                    </Link>
                    <Link rel="canonical" to="/services/product-development">
                      Full Cycle Product Development
                    </Link>
                    <Link
                      rel="canonical"
                      to="/"
                      style={{ cursor: "not-allowed" }}
                    >
                      Cloud Service{" "}
                    </Link>
                    <Link rel="canonical" to="/industries/metaverse">
                      Metaverse{" "}
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Industries</Accordion.Header>
                <Accordion.Body>
                  <div className="links">
                    <Link rel="canonical" to="/industries/igaming">
                      iGaming Industry
                    </Link>
                    <Link
                      rel="canonical"
                      to="/"
                      style={{ cursor: "not-allowed" }}
                    >
                      Cyber Security
                    </Link>
                    <Link rel="canonical" to="/industries/blockchain">
                      Blockchain
                    </Link>
                    <Link rel="canonical" to="/industries/fintech">
                      FinTech
                    </Link>
                    <Link rel="canonical" to="/industries/healthcare">
                      Healthcare
                    </Link>
                    <Link rel="canonical" to="/industries/ai-ml">
                      AI &amp; ML
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col
            lg={3}
            sm={2}
            className="column-2 web-view"
            style={{ paddingLeft: "50px" }}
          >
            <p className="heading">Services</p>
            <div className="links">
              <Link rel="canonical" to="/services/software-development">
                Software Development
              </Link>
              <Link rel="canonical" to="/industries/blockchain">
                Blockchain Development{" "}
              </Link>
              <Link rel="canonical" to="/services/software-development">
                Mobile Apps Development
              </Link>
              <Link rel="canonical" to="/industries/igaming">
                iGaming Development{" "}
              </Link>
              <Link rel="canonical" to="/services/product-development">
                Full Cycle Product Development
              </Link>
              <Link rel="canonical" to="/" style={{ cursor: "not-allowed" }}>
                Cloud Service{" "}
              </Link>
              <Link rel="canonical" to="/industries/metaverse">
                Metaverse{" "}
              </Link>
            </div>
          </Col>
          <Col lg={2} sm={2} className="column-3 web-view">
            <p className="heading">Industries</p>
            <div className="links">
              <Link rel="canonical" to="/industries/igaming">
                iGaming Industry
              </Link>
              <Link rel="canonical" to="/" style={{ cursor: "not-allowed" }}>
                Cyber Security
              </Link>
              <Link rel="canonical" to="/industries/blockchain">
                Blockchain
              </Link>
              <Link rel="canonical" to="/industries/fintech">
                FinTech
              </Link>
              <Link rel="canonical" to="/industries/healthcare">
                Healthcare
              </Link>
              <Link rel="canonical" to="/industries/ai-ml">
                AI &amp; ML
              </Link>
            </div>
          </Col>
          <Col
            md={12}
            sm={12}
            lg={2}
            className="column-4 "
            style={{ width: "20%" }}
          >
            <p className="heading">Address</p>
            <p>
              Plot No. 5, 1st Floor,
              <br className="web-view" />
              Tilak Nagar, Indore,
              <br className="web-view" />
              Madhya Pradesh - 452001
            </p>
            <div className="mobile-no">
              <i className="icon call" />
              +91- 83199-10098
            </div>
            <div className="mobile-no">
              <i className="icon mail" />
              connect@digitalcrew.co.in
            </div>
          </Col>
        </Row>
        <Row className="bottom-row">
          <div className="bottom-left">
          <div
      className='bottom-left'
      // onClick={() => history.push('/privacy-policy')}
      style={{ cursor: 'pointer', color:"black" }}
    >
     <Nav.Link href="/privacy-policy" style={{color:"black"}} >Privacy Policy</Nav.Link>
    </div>
          </div>
          <div className="bottom-right">copyrights@digitalcrew2024</div>
        </Row>
      </Container>
    </>
  );
}

export default Footer;
