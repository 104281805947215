import React, { useEffect } from "react";
import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../components/Hero";
import SpecialCard from "../components/SpecialCard";
import BookLady from "../assests/images/book-lady.png";
import Aws from "../assests/images/aws.png";
import Cloudera from "../assests/images/cloudera.png";
import Googledev from "../assests/images/googledev.png";
import DigitalOcean from "../assests/images/digitalocean.png";
import PrevIconIndTrusted from "../components/Carousel/ArrowIcons/PrevIconIndTrusted";
import NextIconIndTrusted from "../components/Carousel/ArrowIcons/NextIconIndTrusted";
import Fade from "react-reveal/Fade";
import HashtagLogo from "../assests/images/hashtagLogo.png";
import SapLogo from "../assests/images/sap.png";
import HelmetComponent from "../components/Helmet";
import Slider from "react-slick";
import SEO from "../components/SEO";

function Industries() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var settings = {
    dots: false,
    infinite: false,

    slidesToShow: 5,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 0,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div>
      {/* <HelmetComponent title={"Industries - Digital Crew"} description={"Ask us for any assistance, We're here to help! Contact our customer support for any queries! Feel free to mail us at connect@digitalcrew.co.in"} url="industries" /> */}
      <SEO
        title="Get Top IT Industries Services-Digital Crew"
        description="Digital Crew is a prominent provider of top-notch IT-based industry services, including iGaming, blockchain, metaverse, and healthcare development solutions worldwide."
        url="https://digitalcrew.co.in/industries"
        keywords="metaverse, blockchain, fintech software development companies, IT- based industrial services,  healthcare development solutions, data management security"
      />
      <HeroComponent
        bigText={"Industries we help"}
        smallText={
          "Next gen technologies to develop scalable digital solutions for leading industries."
        }
        buttonText={"Talk to our Experts"}
        page={"industries"}
      ></HeroComponent>
      <div className="ind-section1">
        <div className="mid-div">
          <p className="midtitle">TRUSTED BY</p>
        </div>
        <div className="sponsers">
          <Image src={Aws} width="100%" height="100%" />
          <Image src={DigitalOcean} width="100%" height="100%" />
          <Image src={HashtagLogo} width="100%" height="100%" />
          <Image src={Cloudera} width="100%" height="100%" />
          <Image src={SapLogo} width="100%" height="100%" />
        </div>
      </div>
      <div className="ind-section2">
        <SpecialCard
          nextBigText={"iGaming Industry"}
          nextSmallText={
            "We build feature rich igaming solutions to provide a satisfactory experience for the end user."
          }
          bigBGImage={"GoldenCardBackground"}
          linkToPage={"igaming"}
          number={"01"}
        ></SpecialCard>
        <SpecialCard
          nextBigText={"Metaverse"}
          nextSmallText={
            "Live in the Future our experts help you with the most authentic, authorized, confidential and integrated services."
          }
          bigBGImage={"card-2-bg"}
          linkToPage={"metaverse"}
          number={"02"}
        ></SpecialCard>
        <SpecialCard
          nextBigText={"Blockchain"}
          nextSmallText={
            "We increase potential by focusing on improving streamlining processes and data management security."
          }
          bigBGImage={"card-3-bg"}
          linkToPage={"blockchain"}
          number={"03"}
        ></SpecialCard>
        <SpecialCard
          nextBigText={"Fintech"}
          nextSmallText={
            "Smooth and automated functionalities are our main keys to delivering at the user end."
          }
          bigBGImage={"card-4-bg"}
          linkToPage={"fintech"}
          number={"04"}
        ></SpecialCard>
        <SpecialCard
          nextBigText={"Healthcare"}
          nextSmallText={
            "Automated and improved services at one-stop can enhance your Business with Healthcare services growth exponentially."
          }
          bigBGImage={"card-5-bg"}
          linkToPage={"healthcare"}
          number={"05"}
        ></SpecialCard>
        <SpecialCard
          nextBigText={"AI & ML"}
          nextSmallText={
            "AI and ML is the important evolution in Data Processing & Computer Science that is quickly transforming a vast array of industries."
          }
          bigBGImage={"card-6-bg"}
          linkToPage={"ai-ml"}
          number={"06"}
        ></SpecialCard>
      </div>
      <div className="ind-section3">
        <Row>
          <Col lg={6} md={6}>
            <Fade left>
              <p className="heading">DigitalCrew Journey</p>
              <p className="text">
                A complete set of digital learning, that can
                <br />
                empower you in this digital world.
              </p>
              <Button className="btn btn-download">Download Portfolio</Button>
            </Fade>
          </Col>
          <Col lg={6} md={6} className="right-column">
            <Fade right>
              <Image src={BookLady}  style={{width:"100%", height:"100%"}} ></Image>
            </Fade>
          </Col>
        </Row>
      </div>
      <div className="ind-section4">
        <p className="midtitle">Recognitions and Awards</p>
        <div className="midsmalltext">
          Our team of experts have designed and delivered cutting-edge, bespoke
          solutions for
          <br />
          all unique businesses with advanced technology as per industries
          requirements
        </div>
        <div className="award-carousel">
          <Slider {...settings} className="slider-styles">
            <div className="awards">
              <i className="icon revitLogo" />
            </div>
            <div className="awards">
              <i className="icon pokerYaar" />
            </div>
            <div className="awards">
              <i className="icon bigFanLogo" />
            </div>
            <div className="awards">
              <i className="icon pokerWarLogo" />
            </div>
            <div className="awards">
              <i className="icon traviaLogo" />
            </div>
          </Slider>
          {/*                     <Carousel interval={null} prevIcon={<PrevIconIndTrusted />} nextIcon={<NextIconIndTrusted />}>
                        <Carousel.Item>

                        </Carousel.Item>
                        <Carousel.Item>
                            <div className="carousel-item1">
                                <div className="awards">
                                    <i className="icon revitLogo" />
                                </div>
                                <div className="awards">
                                    <i className="icon pokerYaar" />
                                </div>
                                <div className="awards">
                                    <i className="icon bigFanLogo" />
                                </div>
                                <div className="awards">
                                    <i className="icon pokerWarLogo" />
                                </div>
                                <div className="awards">
                                    <i className="icon traviaLogo" />
                                </div>
                            </div>

                        </Carousel.Item>
                    </Carousel> */}
        </div>
      </div>
      <div className="contact-section">
        <p className="midtitle">Get in touch</p>
        <p className="midsmalltext bottom">
          We’d like to hear from you and will be happy to answer your queries
        </p>
      </div>
    </div>
  );
}

export default Industries;
