import React from 'react'
import { Button, Image } from 'react-bootstrap';

function GamesCard(props) {
    const {
        heading,
        para,
        isbtnImage,
        btnImage,
        btnText,
        isRed,
        rightImage,
        linkToPage,
        isRummy
    } = props;
    return (
        <div className={isRed ? "games-card red" : "games-card grey"}>
            <div className={isRummy ? "rummy-leftside leftside":"leftside "}>
                <div className={isRed ? 'heading-black heading ' : " heading heading-red"}>{heading}</div>
                <div className={isRed ? 'para-white para' : "para-black para "} dangerouslySetInnerHTML={{ __html: para }}></div>

                <div className={isRed ? 'btn-div red-link' : "btn-div grey-link"}>
                    <a href={`/industries/games/${linkToPage}`} className='source-link'>
                        Know More <i className='icon black-arrow' />
                    </a>
                </div>

            </div>
            <div className={isRummy ?`rightside ${rightImage} rummy-image` :`rightside ${rightImage}`}></div>
        </div>
    )
}

export default GamesCard