import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import FantasySports2Phones from "../../../assests/images/FantasyNew.png";
import FantasySports3Phones from "../../../assests/images/FantasySportsThreePhoneNew.png";
import FantasySportsFeature1 from "../../../assests/images/FantasySportsFeature1.png";
import FantasySportsFeature2 from "../../../assests/images/FantasySportsFeature2.png";
import FantasySportsFeature3 from "../../../assests/images/FantasySportsFeature3.png";
import FantasySportsFeature4 from "../../../assests/images/FantasySportsFeature4.png";
import FantasySportsFeature5 from "../../../assests/images/FantasySportsFeature5.png";
import FantasySportsFeature6 from "../../../assests/images/FantasySportsFeature6.png";
import FantasySportsFeature7 from "../../../assests/images/FantasySportsFeature7.png";
import FantasySportsFeature8 from "../../../assests/images/FantasySportsFeature8.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function FantasySports() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let features = [
    {
      icon: FantasySportsFeature1,
      name: "User Authorization",
      marginTop: false,
    },
    {
      icon: FantasySportsFeature2,
      name: "Create Team",
      marginTop: true,
    },
    {
      icon: FantasySportsFeature3,
      name: "Join Contest",
      marginTop: false,
    },
    {
      icon: FantasySportsFeature4,
      name: "Private Contest",
      marginTop: true,
    },
    {
      icon: FantasySportsFeature5,
      name: "Leaderboard",
      marginTop: false,
    },
    {
      icon: FantasySportsFeature6,
      name: "Winning amount",
      marginTop: true,
    },
    {
      icon: FantasySportsFeature7,
      name: "Refers and Earn",
      marginTop: false,
    },
    {
      icon: FantasySportsFeature8,
      name: "User Dashboard",
      marginTop: true,
    },
  ];
  return (
    <>
    
      {/* <HelmetComponent title={"Fantasy Sports Game Development Company - Digital Crew"} description={"Digital Crew is one of the advanced and highly skilled fantasy sports game development company that offers high end featured fantasy sports gaming development."} url="industries/games/fantasy-sports" /> */}
      <SEO
        title="Fantasy Sports Software Development | Fantasy Sports App Development "
        description="Digital Crew is the best fantasy sports app, website, and software development company offering reasonable fantasy sports solutions across the globe."
        url="https://digitalcrew.co.in/industries/games/fantasy-sports"
      />
      <HeroComponent
        bigText={
          "Get the best Fantasy sports software<br />development company"
        }
        smallText={
          "Know about the more feature of Fantasy Sports App development."
        }
        buttonText={"Consult Our Experts"}
        page={"FantasySportsHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>{" "}
            <span className="active"> &gt; Fantasy Sports</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">
                Fantasy Sports Software Development Company
              </h1>
              <p className="text">
                Digital Crew is one of the advanced and highly skilled fantasy
                sports development provider companies in India. Our experts
                research for a better scalable igaming app development service
                whether it’s golf, football, soccer, baseball, hockey, or
                cricket. In this competitive world, we offer you streamlined app
                development services that will increase user experience. Digital
                Crew experts help you to implement the best sports app. The
                gaming community has demanded a reliable, secure, precise, and
                realistic gaming experience.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">50+</div>
                    <div className="smalltext">
                      Fantasy Sports Apps
                      <br />
                      Delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side">
              <Image
                src={FantasySports2Phones}
                alt="Fantasy Sports App Development Company"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                Digital Crew provide a wide range of opportunity to make a place
                in the Fantasy Sports industry by hiring experienced developers,
                who know how to align your goals with theirs. With this our
                fantasy app developer help you with elegant UI, instant
                withdrawal, multi option cash add, last minute team updates,
                league filters, entry with multiple teams, transaction history,
                refer and earn, etc. You can have all these services and also
                have the options of custom fantasy sports services as per your
                demand.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image
                src={FantasySports3Phones}
                alt="Fantasy Sports App Development"
              />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Fantasy Sports Features</p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={3}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles  margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="Fantasy Sports Website Development"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            Let’s connect with our consultant,
            <br />
            they can recommend and help you with the best technology and tools
            for any fantasy app development service in India.{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default FantasySports;
