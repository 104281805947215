import React, { useEffect, useState } from "react";
import HeroComponent from "../../components/Hero";
import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import HashtagLogo from "../../assests/images/hashtagLogo.png";
import { submitContactForm } from "../../services";
import CustomToast from "../../components/Toast";
import Fade from "react-reveal/Fade";
import HelmetComponent from "../../components/Helmet";
import Slider from "react-slick";
import bgImage1 from "../../assests/images/prodDevImage.png";
import SEO from "../../components/SEO";
function ProductDev() {
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };
  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let sliderItemsArray = [
    {
      icon: "verified-testing",
      heading: "Verified Testing",
      para: "Testing services by specialized tools, framework and solution.<br />We use special tools for a trusted verified testing process",
      id: 1,
    },
    {
      icon: "fast-delivery",
      heading: "Fast Delivery",
      para: "Timely delivery is very important to keep customer satisfaction.<br /> We ensure fast delivery with quality work. ",
      id: 2,
    },
    {
      icon: "low-budget",
      heading: "Budgets friendly",
      para: "We never compromise with the quality. At pocket friendly prices,<br /> we offer a huge service.",
      id: 3,
    },
  ];
  var settings = {
    dots: false,
    infinite: false,

    slidesToShow: 6,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          infinite: false,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
    
      <SEO
        title="Best Product Development Company | Product App Development Solution"
        description="Digital Crew is the best product development company focusing on startup MVP development and digital online products for their valuable global clients."
        url="https://digitalcrew.co.in/services/product-development"
        keywords="product engineering services, product development services, product development agency, new product development services, best product development companies, smart digital solutions"
      />
      {/* <HelmetComponent title={"Product Development Services - Digital Crew"} description={"Product engineering & digital solutions for startups and enterprises. Get in touch with the best product development company to develop a new product or the existing one."} url="services/product-development" /> */}

      <HeroComponent
        bigText={
          "Product Engineering & Digital Solutions For Startups And Enterprises"
        }
        smallText={
          "An advanced product development service leads your business idea into a successful growth story."
        }
        buttonText={"Book Our Demo"}
        page={"services-pd"}
      ></HeroComponent>
      <div className="productdev-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/services">Services</a>{" "}
            <span className="active"> &gt; Product Development</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} md={5}>
              <div className="img-div">
                <img src={bgImage1} alt="software development"></img>
              </div>
            </Col>
            <Col lg={6} md={7}>
              <div className="text-div">
                <h2 className="heading">
                  Develop product under experts’ guidance{" "}
                </h2>
                <p>
                  The digital crew has a special team of developers to develop
                  your product as per the market demand. We developed your
                  product after deep market research as market competition
                  increases and customers’ demands evolve, now a good product is
                  not the only solution for a successful business. Every
                  business needs to automate technology that can build superior,
                  innovative, advanced and differentiated products. Our expert
                  understands technology evolution, product development service
                  is specially handled to build a strong business presence. Get
                  in touch with the best product development company in India to
                  develop a new product or the existing one.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section3">
          <p className="black-heading">
            Our Team Provided You Through Full Product Development Services.
          </p>
          <p className="white-heading">
            Our skilled team turns your ideas into an effective product and
            marks it as your competitive growth.
            <br />
            Digital crew’s product development service team is ready to shape
            your vision.
          </p>
          <div className="center-box">
            <Slider {...settings} className="slider-styles">
              {sliderItemsArray.map((item) => {
                return (
                  <>
                    <div className="icon-boxes">
                      <div className="icon-box">
                        <i className={`icon ${item.icon}`} />
                      </div>
                      <h2>{item.heading}</h2>
                      <p dangerouslySetInnerHTML={{ __html: item.para }}></p>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
        </div>
        <div className="section4">
          <p className="mid-title">Our trusted patterned companies</p>
          <div className="sponsers">
            <Image src={Aws} alt="software development services" />
            <Image
              src={DigitalOcean}
              alt="software development services provider"
            />
            <Image src={HashtagLogo} alt="software development Company" />
            <Image src={Cloudera} alt="software development company india" />
            <Image src={Googledev} alt="Top Software Developers in India" />
          </div>
        </div>
        <div className="section5">
          <h2 className="mid-title">Our Working Strategy</h2>
          <p className="midsmalltext">
            Product development is essential to growing your business in this
            competitive world.
            <br />
            Our professionals work to create an impactful presence for your
            product.
          </p>
          <div className="mid-section">
            <Row>
              <Col lg={6}>
                <div className="mid-box">
                  <div className="left-side blue">
                    <i className="icon project" />
                  </div>
                  <div className="right-side">
                    <h2>Confidential Your Project</h2>
                    <div className="round-div">
                      We help you to introduce your complete dream product to
                      the marketplace. Your project is secure with us.
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6}>
                <div className="mid-box">
                  <div className="left-side red">
                    <i className="icon people-talking" />
                  </div>
                  <div className="right-side">
                    <h2>Consultation Expert </h2>
                    <div className="round-div">
                      You dream but we understand and architect, construct and
                      develop your idea into a product with proper expert
                      guidance.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mid-box">
                  <div className="left-side yellow">
                    <i className="icon yellow-lens" />
                  </div>
                  <div className="right-side">
                    <h2>Project Observe</h2>
                    <div className="round-div">
                      While building a product, we observe all activities from
                      pre to post launches. Development is an everyday process.
                    </div>
                  </div>
                </div>
              </Col>

              <Col lg={6}>
                <div className="mid-box">
                  <div className="left-side green">
                    <i className="icon moneybag" />
                  </div>
                  <div className="right-side">
                    <h2>Reasonable Quotation</h2>
                    <div className="round-div">
                      We believe in transparency. Digital crew helps you with
                      all digital solutions at pocket-friendly prices
                      irrespective of business size.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="section6">
                    <p className="midtitle">
                        Recognized by the best
                    </p>
                    <div className="recognised-carousel">
                        <Carousel interval={null} prevIcon={<PrevIconIndTrusted />} nextIcon={<NextIconIndTrusted />}>
                            <Carousel.Item>
                                <div className="carousel-item1">
                                    <div className="company-div">
                                        <Image src={HeartW}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2018
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={Brilliant}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2019
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={HeartW}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2020
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={Brilliant}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2018
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                </div>

                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-item1">
                                    <div className="company-div">
                                        <Image src={HeartW}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2018
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={Brilliant}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2019
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={HeartW}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2020
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={Brilliant}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2018
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                </div>


                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-item1">
                                    <div className="company-div">
                                        <Image src={HeartW}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2018
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={Brilliant}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2019
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={HeartW}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2020
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                    <div className="company-div">
                                        <Image src={Brilliant}></Image>
                                        <div className="batch-div">
                                            <i className="icon batch-grey" />
                                            2018
                                        </div>
                                        <p>Our strength lies in
                                            the Comprehensive
                                            suite of services, but also the leap into electronic typesetting</p>
                                    </div>
                                </div>


                            </Carousel.Item>
                        </Carousel>

                    </div>
                </div> */}
        <div className="contact-section">
          <p className="midtitle">
            Estimations &amp; planning for business decisions
          </p>
          <p className="midsmalltext">
            Let’s connect for more and better decisions{" "}
          </p>
          <div className="contact-box">
            <Row>
              <Col lg={8}>
                <form onSubmit={submitFormHandler}>
                  <div className="contact-form">
                    <input
                      className="contact-input"
                      type={"text"}
                      placeholder="Full Name"
                      name={"name"}
                      onChange={handleChange}
                      value={contactFormFields.name}
                    />
                    <Row>
                      <Col lg={6}>
                        <input
                          className="contact-input contact-number"
                          type={"text"}
                          placeholder="Contact Number"
                          name={"phone"}
                          onChange={handleChange}
                          value={contactFormFields.phone}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <input
                          className="contact-input"
                          type={"text"}
                          placeholder="Email Address"
                          name={"email"}
                          onChange={handleChange}
                          value={contactFormFields.email}
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="textarea"
                      placeholder="Project Description"
                      name={"projectdescription"}
                      onChange={handleChange}
                      value={contactFormFields.projectdescription}
                    ></textarea>
                    <Row className="bottom-row">
                      {/*  <Col lg={6}>
                                            <div className='file-div'>
                                                <input className='contact-input-file' type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.docx" placeholder="" name="file" onChange={handleChange} />
                                                <Button className='btn btn-file'>+</Button>
                                                {contactFormFields.file !== null && <p>{contactFormFields.file?.name}</p>}
                                            </div>

                                            <p>Attached File Less then 25 mB</p>
                                        </Col> */}
                      <Col lg={12}>
                        <Button className="btn contact-form-btn" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </Col>
              <Col lg={4}>
                <div className="right-side-inner">
                  <Fade bottom>
                    <p className="bigtext">
                      Let's Get into
                      <br />
                      <span className="red-text">Business discussions </span> !!
                    </p>
                    <p className="smallText">
                      Our strength lies in the comprehensive suite of services
                      we offer to our clients.
                    </p>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext bottom">
            We’d like to hear from you and will be happy to answer your queries
          </p>
        </div>
      </div>
      <CustomToast
        show={showToast}
        onClose={handleHideToast}
        message={toastMessage}
      ></CustomToast>
    </>
  );
}

export default ProductDev;
