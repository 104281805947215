import React, { useEffect } from "react";
import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../components/Hero";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import Sap from "../../assests/images/sap.png";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";
import HashtagLogo from "../../assests/images/hashtagLogo.png";
import blockchainSliderIcon1 from "../../assests/images/blockchainSliderIcon1.png";
import blockchainSliderIcon2 from "../../assests/images/blockchainSliderIcon2.png";
import blockchainSliderIcon3 from "../../assests/images/blockchainSliderIcon3.png";
import blockchainSliderIcon4 from "../../assests/images/blockchainSliderIcon4.png";
import blockchainSliderIcon5 from "../../assests/images/blockchainSliderIcon5.png";
import blockchainSliderIcon6 from "../../assests/images/blockchainSliderIcon6.png";
import blockchainSliderIcon7 from "../../assests/images/blockchainSliderIcon7.png";
import blockchainSliderIcon8 from "../../assests/images/blockchainSliderIcon8.png";
import blockchainSliderIcon9 from "../../assests/images/blockchainSliderIcon9.png";
import blockchainSliderIcon10 from "../../assests/images/blockchainSliderIcon10.png";
import blockchainSliderIcon11 from "../../assests/images/blockchainSliderIcon11.png";
import blockchainSliderIcon12 from "../../assests/images/blockchainSliderIcon12.png";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";

function Blockchain() {
  var settings = {
    dots: false,
    infinite: false,

    slidesToShow: 2,
    slidesToScroll: 22,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 1,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: blockchainSliderIcon1,
      name: "Blockchain app consultation",
      id: 1,
      para: "Digital Crew provides blockchain development services. We help business leaders recognize how blockchain can be a perfect fit to their business model. Our blockchain consulting services and app development solutions include tailor made apps built on high functioning platforms like Hyperledger, EOS, and Stellar.",
    },
    {
      icon: blockchainSliderIcon2,
      name: "MVP development",
      id: 2,
      para: "Digital Crew provides blockchain development services.We believe in testing the waters before launching a full-fledged DApp. So, our Blockchain solutions development revolves around the building and launch of a DApp MVP.",
    },
    {
      icon: blockchainSliderIcon3,
      name: "Smart contracts development",
      id: 3,
      para: "Our team of smart contract developers craft immutable and accurate smart contracts which make the business ecosystem conflict-free.",
    },
    {
      icon: blockchainSliderIcon4,
      name: "End-to-end DApp solutions",
      id: 4,
      para: "DC provides Blockchain development solutions from ideation, design, coding to the final launch, we handle your complete DApp development process from start to finish through our expertise in Blockchain decentralized app development.",
    },
    {
      icon: blockchainSliderIcon5,
      name: "IEO & ICO services",
      id: 5,
      para: "DC Blockchain development services provides all-round IEO & ICO services include preparing your idea for investors, generation of cryptocurrency for distribution, development of white papers, and promotion of your IEO & ICO.",
    },
    {
      icon: blockchainSliderIcon6,
      name: "Crypto exchange development",
      id: 6,
      para: "At Digital Crew, we help you develop a hack-proof platform for the real-time exchange of cryptocurrencies on Android and iOS.",
    },
    {
      icon: blockchainSliderIcon7,
      name: "Custom blockchain solutions",
      id: 7,
      para: "We offer customized Blockchain solutions to integrate with your existing mobile apps, making them Dapps. We also specialize in architecting customized Blockchain software development that aligns with your workflow.",
    },
    {
      icon: blockchainSliderIcon8,
      name: "Crypto wallet development",
      id: 8,
      para: "As part of our Blockchain wallet app development services, we create crypto wallet Dapps that hold hundreds of cryptocurrencies, while carrying information related to your wallet balance and purchase history.",
    },
    {
      icon: blockchainSliderIcon9,
      name: "Hyperledger based solutions",
      id: 9,
      para: "Using hyper ledger, we develop Blockchain Applications that are scalable, immutable, trusted, protected, and support a range of plug-ins.",
    },
    {
      icon: blockchainSliderIcon10,
      name: "Permissioned (private) blockchain",
      id: 10,
      para: "We develop enterprise-grade distributed ledger and codebase, which restricts the participation in the open source distributed ledger.",
    },
    {
      icon: blockchainSliderIcon11,
      name: "Crypto-token-development",
      id: 11,
      para: "Our DC team of blockchain developers also offers the service of digital tokenization. The tokens we create can be used to represent asset values to be traded as cryptocurrency, and loyalty points, instead of cash.",
    },
    {
      icon: blockchainSliderIcon12,
      name: "NFT marketplace development",
      id: 12,
      para: "Our blockchain development services also include building decentralized NFT marketplaces to manage NFT trading, bidding and selling digital assets. We also launch cross-chain marketplaces that help with multichain NFTs in business.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>

      <SEO
        title="Blockchain Development Services | Blockchain Software Development Company
        "
        description="Blockchain Development Company Get blockchain software, and app development services with an expert team of blockchain solution providers from worldwide
        "
        url="https://digitalcrew.co.in/industries/blockchain"
      />
      {/* <HelmetComponent
        title={"Blockchain Software Development Services - Digital Crew"}
        description={
          "Digital Crew is leading blockchain software development service providers that offers edge cutting software solutions in blockchain development."
        }
        url="services/blockchain"
      /> */}
      <div>
        <HeroComponent
          bigText={
            "Secure your business with our blockchain app development services"
          }
          smallText={
            "Start your blockchain development solutions with our cutting-edge expertise."
          }
          buttonText={"Talk to our Experts"}
          page={"BlockchainHomeBanner"}
        ></HeroComponent>
        <div className="blockchain-section">
          <div className="section-breadcrumb">
            <p className="breadcrumb">
              <a href="/industries">Industries</a>
              <span className="active"> &gt; Blockchain</span>
            </p>
          </div>
          <div className="section1">
            <p className="midText">
              Add innovative apps into your business solutions
            </p>
            <p className="midSmallText">
              We offer you the latest technology of decentralized solutions that
              add scalability, clearness, and security to your ecosystem.
            </p>
            <div className="block-section">
              <Slider {...settings} className="slider-styles">
                {sliderItemsArray.map((item) => {
                  return (
                    <>
                      <div className="item-box" id={item.id}>
                        <div className="whitebox">
                          <p
                            dangerouslySetInnerHTML={{ __html: item.name }}
                            className="name"
                          ></p>
                          <p className="para-style">{item.para}</p>
                        </div>
                        <div className="redbox">
                          <img
                            src={item.icon}
                            alt="Blockchain development services"
                          ></img>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div className="section3">
            <Row>
              <Col lg={7} className="card-side">
                <Row>
                  <Col lg={6} className="card-details">
                    <div className="card">
                      <div className="left-side">
                        <i className="icon blockchainCardIcon1" />
                      </div>
                      <div className="right-side">
                        <p className="heading">Agile development process</p>
                        <p className="para">
                          Stay in the loop throughout the development cycle with
                          our sophisticated Agile development process. Every
                          step of our development project is strategized with
                          the aim to provide maximum value to the end users.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="card-details">
                    <div className="card">
                      <div className="left-side">
                        <i className="icon blockchainCardIcon2" />
                      </div>
                      <div className="right-side">
                        <p className="heading">Cost effective development</p>
                        <p className="para">
                          Our blockchain development experts ensure efficiency
                          and reliability throughout the development process and
                          beyond. This helps your project stay within budget and
                          release a world-class product that your customers
                          love.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} className="card-details">
                    <div className="card">
                      <div className="left-side">
                        <i className="icon blockchainCardIcon3" />
                      </div>
                      <div className="right-side">
                        <p className="heading">Quality assurance and testing</p>
                        <p className="para">
                          DC team of experts makes sure that dApps are
                          thoroughly tested and market-ready before launch. Our
                          range of QA and testing services will take care of all
                          your quality assurance needs and help you build a
                          robust and secure blockchain platform.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} className="card-details">
                    <div className="card">
                      <div className="left-side">
                        <i className="icon blockchainCardIcon4" />
                      </div>
                      <div className="right-side">
                        <p className="heading">Round-the-clock support</p>
                        <p className="para">
                          If you got a glitch or bug, we are here to help you.
                          Leverage our 24*7 support and maintenance services
                          post delivery and deployment.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={5} className="para-side">
                <div className="para-inner">
                  Why choose Digital Crew as your blockchain development
                  solutions partner?
                </div>
              </Col>
            </Row>
          </div>
          <div className="section4">
            <p className="midText">Blockchain development process we follow</p>
            <div className="list-items-section">
              <Row>
                <Col lg={6} className="list-item-details">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon1" />
                      Blockchain development Solutions
                    </div>
                    <div className="lower-section">
                      As a leading blockchain development solutions provider
                      company, we begin with what, why, and how blockchain
                      technology can benefit your business and bring trust and
                      transparency to the system. We also deliver a roadmap for
                      blockchain system implementation.
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="list-item-details">
                  <div className="card card-box">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon2" />
                      Requirement analysis
                    </div>
                    <div className="lower-section">
                      We analyze your requirements to understand the features
                      and functionalities to be integrated in your project. This
                      process helps us to create a fantastic development plan
                      for you.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="list-item-details">
                  <div className="card card-box">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon3" />
                      UI/UX design
                    </div>
                    <div className="lower-section">
                      Our Blockchain team creates cutting-edge intuitive UI
                      designs that are simple and convenient for the end users.
                      Simultaneously, our designers work on the blueprint
                      designs, user designs and database designs.
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="list-item-details">
                  <div className="card">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon4" />
                      Prototype
                    </div>
                    <div className="lower-section">
                      We develop a prototype of what your Blockchain app or
                      software would look like. Further, we test the prototype
                      for convenient UI and UX designs.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="list-item-details">
                  <div className="card ">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon5" />
                      <h1 style={{ fontSize: 25 }}>
                        Blockchain App Development Company
                      </h1>
                    </div>
                    <div className="lower-section">
                      We build a model of what your Blockchain app or software
                      would look like. Further, we test the model for convenient
                      UI and UX designs.
                      <br />
                      <br />
                      Our expert Testing team carefully tests every blockchain
                      product to ensure client satisfaction and the best user
                      experience.
                      <br />
                      <br />
                      We support our clients by providing post-maintenance
                      services.
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="list-item-details">
                  <div className="card card-box">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon6" />
                      Quality assurance
                    </div>
                    <div className="lower-section">
                      Our Testing team carefully test every blockchain product
                      to ensure client satisfaction and excellent user
                      experience.
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="list-item-details">
                  <div className="card card-box">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon7" />
                      Deployment
                    </div>
                    <div className="lower-section">
                      We follow the standard protocols while deploying
                      blockchain products on various platforms where they are
                      easily accessible to the prospective audience.
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="list-item-details">
                  <div className="card ">
                    <div className="upper-section">
                      <i className="icon blockchainListIcon8" />
                      Support and maintenance
                    </div>
                    <div className="lower-section">
                      All digital solutions require improvement with time. We
                      support our clients by offering post maintenance services.
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="section5">
            <p className="heading">Tech Stack</p>
            <div className="redbox">
              <div className="list-div">
                <p className="list-heading">Front &amp; Back end</p>
                <p className="list-item">React JS</p>
                <p className="list-item">Angular JS</p>
                <p className="list-item">Node JS</p>
                <p className="list-item">Spring Boot</p>
                <p className="list-item">Unity 3D</p>
                <p className="list-item">Bootstrap/ tailwind css</p>
              </div>
              <div className="list-div">
                <p className="list-heading">DevOps tools</p>
                <p className="list-item">AWS</p>
                <p className="list-item">Docker</p>
                <p className="list-item">Kovenates</p>
                <p className="list-item">Jenkins</p>
                <p className="list-item">Git</p>
              </div>
              <div className="list-div">
                <p className="list-heading">QA tools</p>
                <p className="list-item">Cypress</p>
                <p className="list-item">Selenium</p>
                <p className="list-item">JMeter</p>
              </div>
              <div className="list-div">
                <p className="list-heading">Mobile</p>
                <p className="list-item">React Native</p>
                <p className="list-item">Flutter</p>
                <p className="list-item">Unity 3D</p>
                <p className="list-item">Ionic</p>
                <p className="list-item">Swift</p>
                <p className="list-item">Android Studio</p>
              </div>
            </div>
          </div>
          <div className="section-last">
            <div className="mid-div">
              <p className="midtitle">TRUSTED BY</p>
            </div>
            <div className="sponsers">
              <Image src={Aws} alt="Blockchain development company" />
              <Image src={DigitalOcean} alt="Blockchain app development" />
              <Image src={HashtagLogo} alt="Blockchain developers" />
              <Image src={Cloudera} alt="Blockchain developers" />
              <Image src={Sap} alt="Blockchain developers" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blockchain;
