import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import Ludo2Phones from "../../../assests/images/LudoNew.png";
import Ludo3Phones from "../../../assests/images/LudoThreePhoneNew.png";
import LudoFeature1 from "../../../assests/images/LudoFeature1.png";
import LudoFeature2 from "../../../assests/images/LudoFeature2.png";
import LudoFeature3 from "../../../assests/images/LudoFeature3.png";
import LudoFeature4 from "../../../assests/images/LudoFeature4.png";
import LudoFeature5 from "../../../assests/images/LudoFeature5.png";
import LudoFeature6 from "../../../assests/images/LudoFeature6.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function Ludo() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let features = [
    {
      icon: LudoFeature1,
      name: "Play with Friends",
      marginTop: false,
    },
    {
      icon: LudoFeature2,
      name: "Daily Bonus",
      marginTop: true,
    },
    {
      icon: LudoFeature3,
      name: "Multi-language",
      marginTop: false,
    },
    {
      icon: LudoFeature4,
      name: "Secure Transactions",
      marginTop: true,
    },
    {
      icon: LudoFeature5,
      name: "Login Via Social Media",
      marginTop: false,
    },
    {
      icon: LudoFeature6,
      name: "Live Support",
      marginTop: true,
    },
  ];

  return (
    <>
    
      {/* <HelmetComponent title={"Ludo Game Development Company - Digital crew"} description={"Digital Crew is leading ludo game development company that helps to built high quality and advanced ludo mobile game application."} url="industries/games/ludo" /> */}
      <SEO
        title="Ludo Game App Development Company | Ludo Game Development Services
        "
        description="Ludo Game App Development Company Digital Crew offers world best Ludo game software development and app development services at very low cost.
        "
        url="https://digitalcrew.co.in/industries/games/ludo"
      />
      <HeroComponent
        bigText={
          "Start your business with the best<br/>Ludo Mobile Game Development Company"
        }
        smallText={
          "Connect with the most popular Ludo gaming app of the decade."
        }
        buttonText={"Consult Our Experts"}
        page={"LudoHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>
            <span className="active"> &gt; Ludo</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">Ludo Game App Development Company</h1>
              <p className="text">
                Ludo mobile application game is one of the highly loved games by
                every age group population. It has held a successful track in
                the gaming world. Our experts have developed many number of
                games till today and have a proven record for being the No.1 in
                India. Our experts deliver graphically rich, user-friendly, and
                realistic ludo games backed with many advanced features to
                provide the world’s best Ludo game experience to all the Ludo
                Lover across the world.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">25+</div>
                    <div className="smalltext">
                      Ludo Apps
                      <br />
                      delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={Ludo2Phones} alt="Ludo game app development" />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                The impressive features attract players to play more and more.
                It is a classic cross-platform multiplayer game that supports
                multiplatforms like iOS and Android. Ludo game app developer
                creates high quality and feature games as per the clients needs.
                Hire the best Ludo mobile application developer to create an
                impressive and latest technology game. To make it easier and
                more convenient to play, developers has added various features
                in the game to control and manage the activity with proper
                guidance.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={Ludo3Phones} alt="ludo app development" />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Ludo Features</p>
          <div className="card-section ludo-card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={2}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="Ludo game software development"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            Advance tools and years of experience and knowledge help us to
            deliver you exemplary mobile game applications.
            <br />
            Our high quality and advanced tool games service made us the most
            trusted ludo mobile game application service company.
            <br />
            Let’s connect with our consultant for a custom ludo mobile
            application game solution.
          </p>
        </div>
      </div>
    </>
  );
}

export default Ludo;
