import React, { useEffect, useState } from "react";
import HeroComponent from "../components/Hero";
import { Button, Col, Carousel, Image, Row } from "react-bootstrap";
import ProfileImg from "../assests/images/profileImg.png";
import PrevIconIndTrusted from "../components/Carousel/ArrowIcons/PrevIconIndTrusted";
import NextIconIndTrusted from "../components/Carousel/ArrowIcons/NextIconIndTrusted";
import Coverflow from "react-coverflow";
import img1 from "../assests/images/contact-slider-image1.png";
import img2 from "../assests/images/contact-slider-image2.png";
import img3 from "../assests/images/contact-slider-image3.png";
import img4 from "../assests/images/contact-slider-image4.png";
import img5 from "../assests/images/contact-slider-image5.png";
import homeSliderImage1 from "../assests/images/homeSliderImage1.png";
import homeSliderImage2 from "../assests/images/homeSliderImage2.png";
import homeSliderImage3 from "../assests/images/homeSliderImage3.png";
import homeSliderImage4 from "../assests/images/homeSliderImage4.png";
import homeSliderImage5 from "../assests/images/homeSliderImage5.png";
import homeSliderImage6 from "../assests/images/homeSliderImage6.png";
import homeSliderImage7 from "../assests/images/homeSliderImage7.png";
import Fade from "react-reveal/Fade";
import { submitContactForm } from "../services";
import CustomToast from "../components/Toast";
import HelmetComponent from "../components/Helmet";
import SEO from "../components/SEO";

function ContactUs() {
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };
  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let sliderItemsArray = [
    {
      icon: homeSliderImage1,
      name: "Noah William",
      id: 1,
      para: "We would like to express our satisfaction in the cooperation regarding the development of our Poker and Rummy Games applications. George and the development team did a very professional job. We are satisfied with the solutions given to us and with the communication flow through the project.",
    },
    {
      icon: homeSliderImage2,
      name: "Joyce Barret",
      id: 2,
      para: "Digital Crew has delivered great results on time. We have worked with them for developing Sports Betting software. All through the working tenure, we found their team energetic and highly professional in working. Looking forward to working with them again",
    },
    {
      icon: homeSliderImage3,
      name: "Richard Samuel",
      id: 3,
      para: "We would like to recommend the Digital Crew development team. We have got solutions from them in iGaming; They have capabilities to handle multiple projects in Card Gaming, Fantasy Sports, Sports Book and got the games delivered to our company on time. We look forward to working with them in future projects",
    },
    {
      icon: homeSliderImage4,
      name: "Tim Larson",
      id: 4,
      para: "Job well done and I hope we can continue to grow together. Thank you for the services your team has provided. Your team has been a pleasure to work with, professional and timely.",
    },
    {
      icon: homeSliderImage5,
      name: "Kyle James",
      id: 4,
      para: 'The Digital Crew is always available, extremely knowledgeable, and have a tremendous "can-do" attitude. We have worked on a Sports Book with them and they provided the best solutions.',
    },
    {
      icon: homeSliderImage6,
      name: "Benjamin Liam",
      id: 4,
      para: "I would like to thank Digital Crew and George for all the efforts and suggestions that assisted us in providing Blockchain solutions to us in the area of web, technology.",
    },
    {
      icon: homeSliderImage7,
      name: "Oli John",
      id: 4,
      para: "I worked with George and team Digital Crew for several years on various projects. Unflinchingly reliable, professional and proactive from a technology and security standpoint.",
    },
  ];

  return (
    <>
      <SEO
        title="Contact Us - Digital Crew"
        description="Contact Digital Crew for top Mobile Apps, Software, Web Designing, and Development Services. To know more about how We can help with your project, contact us Now!"
        url="https://digitalcrew.co.in/contact-us"
      />
      {/* <HelmetComponent title={"Contact Us - Digital Crew"}  description={"Ask us for any assistance, We're here to help! Contact our customer support for any queries! Feel free to mail us at connect@digitalcrew.co.in"} url="contact-us" />   */}
      <HeroComponent
        bigText={
          "<span class='redQuotes'>“</span>The biggest part of our digital transformation is ever-changing the way we expect.<span class='redQuotes'>”</span>"
        }
        smallText={
          "We’d like to hear from you and will be happy to answer your queries."
        }
        buttonText={"Talk To Us"}
        page={"contactus"}
      ></HeroComponent>
      <div className="contactus-section">
        <div className="section1">
          <Row lg={12} style={{ justifyContent: "space-between" }}>
            <Col lg={5}>
              <Carousel
                interval={null}
                prevIcon={<PrevIconIndTrusted />}
                nextIcon={<NextIconIndTrusted />}
              >
                {sliderItemsArray.map((item, index) => {
                  return (
                    <Carousel.Item>
                      <div className="carousel-item1">
                        <div className="smart-guide">
                          <div className="client-text">#clientspeak</div>
                          <div className="info-div">
                            <div className="image-box">
                              <Image src={item.icon}></Image>
                            </div>
                            <p className="name">{item.name}</p>
                            <p className="info-text">{item.para}</p>
                          </div>
                        </div>
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </Col>
            <Col lg={6}>
              <div className="request-to-demo">
                <div className="top-text">Let's get in touch</div>
                <div className="contact-form">
                  <form onSubmit={submitFormHandler}>
                    <input
                      className="contact-input"
                      type={"text"}
                      placeholder="Full Name"
                      name={"name"}
                      onChange={handleChange}
                      value={contactFormFields.name}
                    />
                    <Row>
                      <Col lg={6}>
                        <input
                          className="contact-input contact-number"
                          type={"text"}
                          placeholder="Contact Number"
                          name={"phone"}
                          onChange={handleChange}
                          value={contactFormFields.phone}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <input
                          className="contact-input"
                          type={"text"}
                          placeholder="Email Address"
                          name={"email"}
                          onChange={handleChange}
                          value={contactFormFields.email}
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="textarea"
                      placeholder="Project Description"
                      name={"projectdescription"}
                      onChange={handleChange}
                      value={contactFormFields.projectdescription}
                    ></textarea>
                    <Row className="bottom-row">
                      {/* <div className='upper-div'>
                                            <p>Attached File Less then 25 mB</p>
                                            <div className='file-div'>
                                                <input className='contact-input-file' type={"file"} placeholder="" />
                                                <Button className='btn btn-file'>+</Button>
                                            </div>
                                        </div> */}

                      <Button className="btn contact-form-btn" type="submit">
                        Submit
                      </Button>
                      <p className="grey-subtext">
                        Your plan is safe by our confidentiality and
                        Non-disclosure Agreement.
                      </p>
                    </Row>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="section2">
          <div className="center-box">
            <div className="left-box">
              <p className="suggestion">
                Connect with our expertise
                <br />
                for more discussion
              </p>
              <div className="btn-box">
                <Button className="btn btn-red">
                  <a href="https://wa.me/8319910098?text=" target="_blank">
                    <i className="icon whatsapp-white"></i> Whatsapp
                  </a>
                </Button>
                <Button className="btn btn-red">
                  <a
                    href="https://msng.link/o/?digitalcrew_0721=sk"
                    target="_blank"
                  >
                    <i className="icon skype-white"></i> Skype
                  </a>
                </Button>
              </div>
            </div>
            <div className="right-box"></div>
          </div>
        </div>
        <div className="section3">
          <p className="midtitle">Our Office</p>
          <p className="midsmalltext">
            Take a glance at Digital Crew’s holy workplace
          </p>
          <div className="office-carousel">
            <Coverflow
              width={960}
              height={480}
              displayQuantityOfSide={2} 
              navigation={false}
              enableHeading={false}
              infiniteScroll={false}
              enableScroll={false}
            >
              <img src={img1} alt="title or description" height="100%" width="100%" />
              <img src={img2} alt="title or description" height="100%" width="100%"/>
              <img src={img3} alt="title or description" height="100%" width="100%"/>
              <img src={img4} alt="title or description" height="100%" width="100%"/>
              <img src={img5} alt="title or description" height="100%" width="100%"/>
            </Coverflow>
          </div>
        </div>
        <div className="section4">
          <p className="mid-text">
            Plot No. 5, 1st Floor, Tilak Nagar, Indore, Madhya Pradesh - 452001
          </p>
          <div className="details">
            <div className="mobile-no">
              <i className="icon call" />
              +91- 83199-10098
            </div>
            <div className="mobile-no">
              <i className="icon mail" />
              connect@digitalcrew.co.in
            </div>
          </div>
        </div>
      </div>
      <CustomToast
        show={showToast}
        onClose={handleHideToast}
        message={toastMessage}
      ></CustomToast>
    </>
  );
}

export default ContactUs;
