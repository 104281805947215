import React from 'react'
import Toast from 'react-bootstrap/Toast';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
function CustomToast(props) {
    const { message, type, show, onClose } = props;
    return (
        <>
            <Snackbar
                open={show}
                autoHideDuration={6000}
                onClose={onClose}
                message={message}
            />
        </>
    );
}

export default CustomToast