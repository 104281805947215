import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import TwoPhones from "../../../assests/images/PokerNew.png";
import ThreePhones from "../../../assests/images/threephones.png";
import PokerFeature1 from "../../../assests/images/PokerFeature1.png";
import PokerFeature2 from "../../../assests/images/PokerFeature2.png";
import PokerFeature3 from "../../../assests/images/PokerFeature3.png";
import PokerFeature4 from "../../../assests/images/PokerFeature4.png";
import PokerFeature5 from "../../../assests/images/PokerFeature5.png";
import PokerFeature6 from "../../../assests/images/PokerFeature6.png";
import PokerFeature7 from "../../../assests/images/PokerFeature7.png";
import PokerFeature8 from "../../../assests/images/PokerFeature8.png";
import PokerFeature9 from "../../../assests/images/PokerFeature9.png";
import PokerFeature10 from "../../../assests/images/PokerFeature10.png";
import PokerFeature11 from "../../../assests/images/PokerFeature11.png";
import PokerFeature12 from "../../../assests/images/PokerFeature12.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function DCPoker() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let features = [
    {
      icon: PokerFeature1,
      name: "Texas Hold'em",
      marginTop: false,
    },
    {
      icon: PokerFeature2,
      name: "Rewards & Loyalty",
      marginTop: true,
    },
    {
      icon: PokerFeature3,
      name: "5 Card Omaha",
      marginTop: false,
    },
    {
      icon: PokerFeature4,
      name: "Multi Language",
      marginTop: true,
    },
    {
      icon: PokerFeature5,
      name: "4 Card PLO",
      marginTop: false,
    },
    {
      icon: PokerFeature6,
      name: "RNG Certified Games",
      marginTop: true,
    },
    {
      icon: PokerFeature7,
      name: "Payment Gateway",
      marginTop: false,
    },
    {
      icon: PokerFeature8,
      name: "Anti Fraud System",
      marginTop: true,
    },
    {
      icon: PokerFeature9,
      name: "Tournaments",
      marginTop: false,
    },
    {
      icon: PokerFeature10,
      name: "Responsible Gaming",
      marginTop: true,
    },
    {
      icon: PokerFeature11,
      name: "Sit & Go",
      marginTop: false,
    },
    {
      icon: PokerFeature12,
      name: "Back Office & CRM",
      marginTop: true,
    },
  ];
  return (
    <>
    
      {/* <HelmetComponent title={"Poker Game Development Company - Digital Crew"} description={"Digital Crew is one of the leading company poker game development company who is highly skilled at developing poker games and poker software."} url={"/industries/games/dc-poker"}/> */}
      <SEO
        title="Poker Game App Development Company | Poker Software Development"
        description="Digital Crew is the top Poker Game Development company that builds customized poker apps and software development solutions to their global clients."
        url="https://digitalcrew.co.in/industries/games/dc-poker"
      />
      <HeroComponent
        bigText={"Best poker game development company"}
        smallText={
          "Connect with the latest and most advanced poker game<br />development company."
        }
        buttonText={"Consult Our Experts"}
        page={"PokerHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>
            <span className="active"> &gt; Poker</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">Poker Game Development Company</h1>
              <p className="text">
                Our poker game development service is leading in India. Our
                professionals build creative poker game apps that can be played
                on Android and iOS platforms. Hire professionals for latest
                features and advanced Poker game development services including
                Texas Hold’em, Omaha, PLO, OFC. Talk to our consultant for the
                best poker game development service in India. Build an ultimate
                poker game with a desire to compete in the gaming industry and
                get a large audience to play online poker games on your platform
                with a great user engagement.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">40+</div>
                    <div className="smalltext">
                      Poker Apps
                      <br />
                      Delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={TwoPhones} alt="Poker Software development company" />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                Our developer understands updates in technology. Hire a poker
                game app developer for the engaging user interface, multiplayer
                features, payment security, multi-table tournament,
                cross-browser functionality and rewards. We have the best poker
                app development service company in India. If you want other
                value-added services or custom poker app development services,
                we would love to cater for you with our expertise.
              </p>
            </Col>
            <Col lg={6} className="right-side">
              <Image src={ThreePhones} alt="Poker Game Development Services" />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Poker Features</p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={2}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="poker software developers india"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            Here, Digital Crew can help you with all these services and also
            provide custom poker app development service in India at a pocket
            <br />
            friendly price with rich qualities of the features. Let’s connect to
            hire a poker game developer for your business.
          </p>
        </div>
      </div>
    </>
  );
}

export default DCPoker;
