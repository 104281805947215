import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import HeroComponent from "../components/Hero";
import PersonDesc from "../components/PersonDesc";
import ProfileImg from "../assests/images/profileImg.png";
import Founder from "../assests/images/elishaRuth.png";
import CoFounder from "../assests/images/coFounder.png";
import ProjectManager from "../assests/images/projectManager.png";
import CreativeHead from "../assests/images/creativeHead.png";
import SeniorHeadDev from "../assests/images/seniorHeadDev.png";
import HeadDev from "../assests/images/headDev.png";
import Dev1 from "../assests/images/dev1.png";
import Designer1 from "../assests/images/designer1.png";
import Dev2 from "../assests/images/dev2.png";
import Dev3 from "../assests/images/dev3.png";
import Dev4 from "../assests/images/dev4.png";
import HelmetComponent from "../components/Helmet";
import SEO from "../components/SEO";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        title="Who We Are | About Digital Crew | Best Web and App Development Company
        "
        description="Digital Crew is the most prominent IT company based in India. We offer iGaming, blockchain, solutions, Web, and app development services with advanced technology.
        "
        url="https://digitalcrew.co.in/about-us"
      />

      <HeroComponent
        bigText={
          "Your dedicated digital solutions experts are just one click away"
        }
        smallText={
          "Here we customized our services to meet your business goals"
        }
        buttonText={"Talk with the Experts"}
        page={"aboutus"}
      ></HeroComponent>
      <div className="about-section">
        <div className="section1">
          <div className="info-div">
            <div className="grey-box">
              <p className="redtext">Who</p>
              <p className="greytext">We Are?</p>
            </div>
            <p className="info-text">
              Digital Crew is a top-notch service provider company in India for
              all your digital solutions. It all started with a vision to help
              every business owner access the same high-quality,
              custom-developed solution regardless of size. Our highly skilled
              professionals allow you to walk in a world of opportunity where
              your desired product could be human-centred. We are a group of
              creatively qualified workforce with an innovation, growth mindset,
              and always the courage to question conventions.
            </p>
          </div>
          <div className="info-div reverse">
            <div className="grey-box">
              <p className="redtext">What</p>
              <p className="greytext">We Do?</p>
            </div>
            <p className="info-text">
              Our team empowers millions of business owners worldwide to realise
              their dream—our wide range of quality products at pocket-friendly
              prices and excellent services for business growth. We are a
              one-stop solution for all your Blockchain, Metaverse, Healthcare,
              Mobile Apps, Software, and iGaming development services in India.
              We are from the world of design thinking, focused on a tremendous
              level of user engagement, and believe in giving the best
              experience to the end users.
            </p>
          </div>
        </div>
        <div className="section2">
          <p className="midtitle">Our Trusted Client</p>
          <div className="midsmalltext">
            We provide complete support to our clients to boost their business.{" "}
          </div>
          <div className="company-div">
            <Row>
              <Col className="company ford-logo "></Col>
              <Col className="company prestoLogo"></Col>
              <Col className="company aggosLogo "></Col>
              <Col className="company michelinLogo"></Col>
            </Row>
            <Row className="row-second">
              <Col className="company tescoLogo"></Col>
              <Col className="company sodexoLogo"></Col>
              <Col className="company infosysLogo"></Col>
              <Col className="company cargomaticLogo "></Col>
            </Row>
            <Row className="row-second">
              <Col className="company ceatLogo"></Col>
              <Col className="company cnbcLogo"></Col>
              <Col className="company doveLogo"></Col>
              <Col className="company apolloLogo "></Col>
            </Row>
          </div>
        </div>
        <div className="section3">
          {/*                     <p className="midtitle">Our Talented Team’s</p>
                    <div className="midsmalltext">Our strength lies in the comprehensive suite of services</div>
                    <div className='team-area'>
                        <Row>
                            <div className='top-row'>
                                <PersonDesc
                                    profileImg={Founder}
                                    name={"Elisha Ruth"}
                                    designation={"Founder"}
                                />
                                <PersonDesc
                                    profileImg={CoFounder}
                                    name={"Ashish Vyas"}
                                    designation={"Co - Founder"}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className='second-row'>
                                <PersonDesc
                                    profileImg={ProjectManager}
                                    name={"George Michael"}
                                    designation={"Product Manager"}
                                />
                                <PersonDesc
                                    profileImg={CreativeHead}
                                    name={"Mujahid Ali Faisal"}
                                    designation={"Creative Head"}
                                />
                                <PersonDesc
                                    profileImg={SeniorHeadDev}
                                    name={"Shivam Dixit"}
                                    designation={"Senior head Developer"}
                                />
                                <PersonDesc
                                    profileImg={HeadDev}
                                    name={"Vivek Parmar"}
                                    designation={"Head Developer"}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className='second-row'>

                                <PersonDesc
                                    profileImg={Dev1}
                                    name={"Naveen Sheoran"}
                                    designation={"Developer"}
                                />
                                <PersonDesc
                                    profileImg={Designer1}
                                    name={"Anwar Husain"}
                                    designation={"Designer"}
                                />
                                <PersonDesc
                                    profileImg={Dev2}
                                    name={"Abdul Salaam"}
                                    designation={"Developer"}
                                />
                                <PersonDesc
                                    profileImg={Dev3}
                                    name={"Apoorv Vyas"}
                                    designation={"Developer"}
                                />
                            </div>
                        </Row>
                        <Row>
                            <div className='second-row'>
                                <PersonDesc
                                    profileImg={Dev4}
                                    name={"Sakshi Joshi"}
                                    designation={"Developer"}
                                />
                                <PersonDesc
                                    profileImg={ProfileImg}
                                    name={"Rajesh Chaudhary"}
                                    designation={"Designer"}
                                />
                                <PersonDesc
                                    profileImg={ProfileImg}
                                    name={"Vishal Shrestha"}
                                    designation={"Developer"}
                                />

                            </div>
                        </Row>
                    </div> */}
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext">
            We’d like to hear from you and will be happy to answer your queries
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
