import React, { useEffect } from "react";
import { Carousel, Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../components/Hero";
import PlayStore from "../../assests/images/playstore.png";
import AppStore from "../../assests/images/appstore.png";
import GamesCard from "../../components/GamesCard";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";

function Games() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <HelmetComponent title={"iGaming Software Development Company - Digital Crew"} description={"Digital Crew is one of the leading company in igaming software development, that offers fully managed sportsbook, online casino and poker software. "} url="industries/igaming" />   */}
      <SEO
        title="iGaming Software Development | iGaming Web And App Development"
        description="iGaming Software Development Get the latest iGaming Software, web, app design, and development solutions from top iGaming Development service provider Company.
        "
        url="https://digitalcrew.co.in/industries/igaming"
      />
      <HeroComponent
        bigText={"Creative and Innovative iGaming Experts "}
        smallText={
          "We strive to take your gaming business to the next level with our creativity."
        }
        buttonText={"Consult Our Experts"}
        page={"iGamingHomeBanner"}
      ></HeroComponent>
      <div className="games-section">
        <div className="section-breadcrumb">
          <p className="breadcrumb">
            <a href="/industries">Industries</a>
            <span className="active"> &gt; iGaming Industry</span>
          </p>
        </div>
        <div className="carousel-section">
          <div className="carousel-item1">
            <div className="left-side">
              <h2 className="heading">Poker</h2>
              <p className={"description"}>
                Digital Crew has now made the dream come true by providing the
                World class poker game experience, with great UI/UX that can be
                integrated into iOS and Android platforms. Get customisation of
                Poker App development with advanced features.
              </p>
              <div className="source">
                <a href="/industries/games/dc-poker" className="source-link">
                  Know More <i className="icon black-arrow" />
                </a>
              </div>
            </div>
            <div className={`right-side `}></div>
          </div>
        </div>
        <div className="card-section">
          <Row>
            <Col lg={6} md={12}>
              <GamesCard
                heading={"TeenPatti"}
                para={
                  "Digital Crew is a professional Teen Patti Game development company having expertise in Teen Patti app development and software development services."
                }
                isbtnImage
                btnImage={PlayStore}
                rightImage={"teenpatti"}
                linkToPage={"teen-patti"}
              ></GamesCard>
            </Col>
            <Col lg={6} md={12}>
              <GamesCard
                heading={"BlackJack"}
                para={
                  "Build the most engaging and unique Blackjack Game App and software with our experienced team. We provide advanced technology tools to your igaming development service. "
                }
                rightImage={"blackjack"}
                btnText={"Coming Soon"}
                linkToPage={"blackjack"}
                isRed
              ></GamesCard>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={12}>
              <GamesCard
                heading={"Trivia"}
                para={
                  "Trivia has gotten popular in the past few years and Digital Crew has made sure to provide our clients the state-of-art solution with unique creativity"
                }
                rightImage={"trivia"}
                btnText={"Download Link"}
                linkToPage={"trivia"}
                isRed
              ></GamesCard>
            </Col>

            <Col lg={6} md={12}>
              <GamesCard
                heading={"Ludo"}
                para={
                  "Graphically rich and giving a realistic experience is what makes Ludo game loved to play by everyone. Considering this, Digital Crew is providing the best Ludo game development services to our clients."
                }
                isbtnImage
                btnImage={PlayStore}
                rightImage={"ludo"}
                linkToPage={"ludo"}
              ></GamesCard>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <GamesCard
                heading={"Fantasy Sports"}
                para={
                  "Digital Crew is one of the advanced and highly skilled fantasy sports development provider companies in India. Streamlined app development services that will increase user experience."
                }
                isbtnImage
                btnImage={PlayStore}
                rightImage={"fantasysports"}
                linkToPage={"fantasy-sports"}
              ></GamesCard>
            </Col>
            <Col lg={6} md={12}>
              <GamesCard
                heading={"Rummy"}
                para={
                  "Assisting you by providing the complete solution for Rummy Game app development for players to have an adventurous experience and help you to expand your existing online gaming business."
                }
                rightImage={"rummy"}
                btnText={"Coming Soon"}
                linkToPage={"rummy"}
                isRed
                isRummy
              ></GamesCard>
            </Col>
          </Row>
          <Row>
            <Col lg={12} style={{ padding: "0px 58px" }}>
              <GamesCard
                heading={"Sports betting & Casino"}
                para={
                  "Digital Crew's experts have made sure to provide best sports betting<br/> software development that is fully customisable as per your needs.<br/> Keeping this in mind, Digital Crew takes pride in providing the best<br/> custom betting software development services.   "
                }
                rightImage={"SportBettingLaptop"}
                //btnText={"Coming Soon"}
                isbtnImage
                linkToPage={"casino-games"}
                btnImage={PlayStore}
              ></GamesCard>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Games;
