import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import HeroComponent from "../../../components/Hero";
import CasinoGames2Phones from "../../../assests/images/SportBettingLaptop.png";
import CasinoGames3Phones from "../../../assests/images/SportsBettingThreePhones.png";
import CasinoGamesFeature1 from "../../../assests/images/CasinoGamesFeature1.png";
import CasinoGamesFeature2 from "../../../assests/images/CasinoGamesFeature2.png";
import CasinoGamesFeature3 from "../../../assests/images/CasinoGamesFeature3.png";
import CasinoGamesFeature4 from "../../../assests/images/CasinoGamesFeature4.png";
import CasinoGamesFeature5 from "../../../assests/images/CasinoGamesFeature5.png";
import CasinoGamesFeature6 from "../../../assests/images/CasinoGamesFeature6.png";
import HelmetComponent from "../../../components/Helmet";
import SEO from "../../../components/SEO";

function CasinoGames() {
  let features = [
    {
      icon: CasinoGamesFeature1,
      name: "Flexible & Secure Payment Options",
      marginTop: false,
    },
    {
      icon: CasinoGamesFeature2,
      name: "Live Betting",
      marginTop: true,
    },
    {
      icon: CasinoGamesFeature3,
      name: "Multi-Language",
      marginTop: false,
    },
    {
      icon: CasinoGamesFeature4,
      name: "Live Match Updates",
      marginTop: true,
    },
    {
      icon: CasinoGamesFeature5,
      name: "Multiple Bets",
      marginTop: false,
    },
    {
      icon: CasinoGamesFeature6,
      name: "Wide Market Coverage",
      marginTop: true,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      {/* <HelmetComponent title={"Sports Betting & Casino Game Development Company - Digital Crew"} description={"Digital Crew is one of the leading software development company that offers world-class software solutions in sports betting and gaming industry."} url="industries/games/casino-games" /> */}
      <SEO
        title="Sports Betting App Development Company | Casino Game App Development "
        description="Digital Crew is a prominent Sports Betting App Development Company. Get feature-rich, secure casino game app, web, and software development services at low cost."
        url="https://digitalcrew.co.in/industries/games/casino-games"
      />
      <HeroComponent
        bigText={
          "Get a hi-tech Sports book & Casino games<br/>software solutions"
        }
        smallText={
          "We are an Innovative, Experienced, and Scalable<br/>Casino games development company."
        }
        buttonText={"Consult Our Experts"}
        page={"SportsbettingCasinoHomeBanner"}
      ></HeroComponent>
      <div className="gamepages-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/industries/igaming">iGaming</a>{" "}
            <span className="active"> &gt; Sports Betting &amp; Casino</span>
          </p>
        </div>
        <div className="section2">
          <Row>
            <Col lg={6} className="left-side">
              <h1 className="heading">
                Sports Betting App Development Company
              </h1>
              <p className="text">
                Digital crew helps you with the most featured and advanced
                technology software for sports betting. Our creative way of
                working always covers all the requisite and unparalleled
                features for enhancing the betting experience of your users. To
                develop an amazing user experience, here we cater customization
                as per your requirements.
                <br></br>
                <br></br>
                We provide custom sports betting software development solutions
                that help you to build your business and help you to achieve
                your targets. Our experts are here to fulfil your demands
                whether you want a brand-specific design or want to add some
                more unique and innovative features. Our latest and advanced
                betting software supports all the popular bet types.
              </p>
              <div className="lower-section">
                <Row>
                  <Col lg={6}>
                    <div className="bigtext">5</div>
                    <div className="smalltext">
                      year of active
                      <br />
                      engagement
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="bigtext">30+</div>
                    <div className="smalltext">
                      Sport Betting &amp; Casino
                      <br />
                      Games App Delivered.
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6} className="right-side sports-betting-right-side">
              <Image
                src={CasinoGames2Phones}
                alt="sports betting software development "
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="left-side">
              <p className="heading">Why to choose us</p>
              <p className="text">
                Our professionals stay updated about all the latest
                technologies; to create cutting-edge solutions.
                <br />
                <br />
                Lead the igaming industry with an excellent sports betting
                software development company in India.
                <br />
                <br />
                At Digital Crew, we believe in providing a unique user
                experience by offering customizable solutions tailored to your
                specific needs. We aim to develop betting software that exceeds
                your expectations and keeps your business ahead in the
                competitive gambling industry.
                <br />
                <br />
                Our latest and advanced betting software is designed to support
                a wide range of popular bet types, ensuring your users can
                access a comprehensive selection of options. So whether it's
                sports betting, casino games, or any other form of gambling, our
                software has covered you.
              </p>
            </Col>
            <Col lg={6} className="right-side ">
              <Image
                src={CasinoGames3Phones}
                style={{ width: "432px", height: "274px" }}
                alt="sports betting software development company "
              />
            </Col>
          </Row>
        </div>
        <div className="section3">
          <p className="heading" style={{ marginBottom: "50px" }}>
            Tech Stack
          </p>
          <div className="redbox">
            <div className="list-div">
              <p className="list-heading">Front &amp; Back end</p>
              <p className="list-item">React JS</p>
              <p className="list-item">Angular JS</p>
              <p className="list-item">Node JS</p>
              <p className="list-item">Spring Boot</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Bootstrap/ tailwind css</p>
            </div>
            <div className="list-div">
              <p className="list-heading">DevOps tools</p>
              <p className="list-item">AWS</p>
              <p className="list-item">Docker</p>
              <p className="list-item">Kovenates</p>
              <p className="list-item">Jenkins</p>
              <p className="list-item">Git</p>
            </div>
            <div className="list-div">
              <p className="list-heading">QA tools</p>
              <p className="list-item">Cypress</p>
              <p className="list-item">Selenium</p>
              <p className="list-item">JMeter</p>
            </div>
            <div className="list-div">
              <p className="list-heading">Mobile</p>
              <p className="list-item">React Native</p>
              <p className="list-item">Flutter</p>
              <p className="list-item">Unity 3D</p>
              <p className="list-item">Ionic</p>
              <p className="list-item">Swift</p>
              <p className="list-item">Android Studio</p>
            </div>
          </div>
        </div>
        <div className="section4">
          <p className="heading">Sports betting &amp; Casino Features</p>
          <div className="card-section">
            <Row>
              {features.map((item, index) => {
                return (
                  <Col
                    key={index}
                    lg={2}
                    sm={4}
                    xs={6}
                    className={
                      item.marginTop
                        ? "column-styles  margin-top-style hvr-wobble-vertical"
                        : "column-styles hvr-wobble-vertical"
                    }
                  >
                    <div className="white-circle">
                      <img
                        src={item.icon}
                        alt="sports betting software development Services"
                      ></img>
                    </div>
                    <p>{item.name}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <div className="section5">
          <p className="mid-text">
            The platform is developed by our team of professionals with years of
            experience in the industry,
            <br />
            having provided solutions to over 150 clients worldwide.
            <br />
            The sportsbook can be built as a standalone product or integrated
            into your casino. Get in touch with our experts now.
          </p>
        </div>
      </div>
    </>
  );
}

export default CasinoGames;
