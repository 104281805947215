import React, { useEffect, useState } from "react";
import HeroComponent from "../../components/Hero";
import { Button, Carousel, Col, Image, Row } from "react-bootstrap";
import Aws from "../../assests/images/aws.png";
import Cloudera from "../../assests/images/cloudera.png";
import Googledev from "../../assests/images/googledev.png";
import DigitalOcean from "../../assests/images/digitalocean.png";
import HashtagLogo from "../../assests/images/hashtagLogo.png";
import Slider from "react-slick";
import SearchBigIcon from "../../assests/images/SearchBigIcon.png";
import ComputerSearch from "../../assests/images/ComputerSearch.png";
import PenBigIcon from "../../assests/images/PenBigIcon.png";
import SparkleBigIcon from "../../assests/images/SparkleIcon.png";
import SoftwareDevImage1 from "../../assests/images/software-dev-image1.png";
import SoftwareDevImage2 from "../../assests/images/software-dev-image2.png";
import SoftwareDevImage3 from "../../assests/images/SoftwareDevImage3.png";
import Softwaredevicon1 from "../../assests/images/softwaredevicon1.png";
import Softwaredevicon2 from "../../assests/images/softwaredevicon2.png";
import Softwaredevicon3 from "../../assests/images/softwaredevicon3.png";
import Softwaredevicon4 from "../../assests/images/softwaredevicon4.png";
import Fade from "react-reveal/Fade";
import { submitContactForm } from "../../services";
import HelmetComponent from "../../components/Helmet";
import SEO from "../../components/SEO";
function SoftwareDev() {
  const [contactFormFields, setContactFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    projectdescription: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const handleShowToast = () => {
    setShowToast(true);
  };
  const handleHideToast = () => {
    setShowToast(false);
  };
  const submitFormHandler = async (e) => {
    e.preventDefault();

    const payload = {
      fields: [
        {
          name: "email",
          value: contactFormFields.email,
        },
        {
          name: "name",
          value: contactFormFields.name,
        },
        {
          name: "phone",
          value: contactFormFields.phone,
        },
        {
          name: "projectdescription",
          value: contactFormFields.projectdescription,
        },
        /*   {
                      name: "file",
                      value: contactFormFields.file
                  }, */
      ],
    };
    const res = await submitContactForm(payload);
    if (res?.inlineMessage === "Thanks for submitting the form.") {
      setContactFormFields({
        name: "",
        email: "",
        phone: "",
        projectdescription: "",
      });
      handleShowToast();
      setToastMessage("We'll get back to you soon!");
    } else {
      handleShowToast();
      setToastMessage("Some error occurred. Please try again !");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file") {
      setContactFormFields({
        ...contactFormFields,
        [name]: e.target.files[0],
      });
      // uploadFileHandler(e);
    } else {
      setContactFormFields({
        ...contactFormFields,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  var settings = {
    dots: false,
    infinite: true,

    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          initialSlide: 0,
        },
      },
    ],
  };

  let sliderItemsArray = [
    {
      icon: Softwaredevicon1,
      name: "Multi-platform",
      id: 1,
    },
    {
      icon: Softwaredevicon2,
      name: "QA products ",
      id: 2,
    },
    {
      icon: Softwaredevicon3,
      name: "Feature rich",
      id: 3,
    },
    {
      icon: Softwaredevicon4,
      name: "Post launch services",
      id: 4,
    },
  ];

  return (
    <>
      <SEO
        title="Software Development Company | Software Development Services Provider"
        description="Digital Crew is a leading Software Development Company offering high-quality software development solutions with the help of an expert software developers team."
        url="https://digitalcrew.co.in/services/software-development"
        keywords="software development company, best software testing, Software development solution, mobile app development company, custom web applications"
      />
      {/* <HelmetComponent
        title={"Software Development Company - Digital Crew"}
        description={
          "Digital Crew is the best software development company that helps to building an app from elevation your development strategy, designing feature rich experiences by adding creativity."
        }
        url="services/software-development"
      /> */}

      <HeroComponent
        bigText={"Building Enterprise Application For The World"}
        smallText={
          "Our software development services are built to evolve your business idea into a successful growth story."
        }
        buttonText={"Book Our Demo"}
        page={"services-sd"}
      ></HeroComponent>
      <div className="software-dev-section">
        <div className="section1">
          <p className="breadcrumb">
            <a href="/services">Services</a>
            <span className="active"> &gt; Software Development</span>
          </p>
        </div>
        <div className="section2">
          <div className="left-side">
            <img
              src={SoftwareDevImage1}
              alt="mobile application development services"
            ></img>
          </div>
          <div className="right-side">
            <div className="content-section">
              <p className="redHeading">Enterprise Application</p>
              <p className="blackHeading">Development Services</p>
              <p className="para">
                Digital Crew delivers Enterprise Application development
                solutions to every business irrespective of its size. Our
                skilled developers have years of experience in developing
                custom, highly featured, advanced functional, and futuristic
                appications. Our developers tend to develop numerous custom web
                applications for a mix of industry verticals in the least
                possible time.
              </p>
            </div>
          </div>
        </div>

        <div className="section3">
          <Row>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon1"></i>
                </div>
                <p className="heading">Application Development</p>
                <p className="content">
                  Enterprise application development has a set of users and
                  customer expectations. Our developers know the art of
                  developing an application that shines and get attention from
                  the competition. Our consultants help you to choose the best
                  Enterprise Application development service to grow your
                  business.
                </p>
              </div>
            </Col>

            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon2"></i>
                </div>
                <p className="heading">UI/UX Design</p>
                <p className="content">
                  To provide an amazing user experience, our UI/UX Developer
                  focused on creating highly functional rich, and reliable
                  applications. We have created many Enterprise Application with
                  impeccable UI/UX designs service for different businesses
                  according to their niche audience and market.
                </p>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon3"></i>
                </div>
                <p className="heading">Custom Enterprise Application</p>
                <p className="content">
                  Customized Enterprise application development service is in
                  high demand. Our experts are adept at fulfilling the various
                  requirement of your business by building robust, scalable, and
                  advanced Enterprise solutions. Digital Crew helps you build a
                  custom Enterprise solution that presents your business's
                  authentic voice to your customers.
                </p>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon4"></i>
                </div>
                <p className="heading">Software Testing</p>
                <p className="content">
                  Our QA developer ensue that your Enterprise application
                  development is glitch free. We perform several testing’s first
                  to check for bugs that can halt your operations process in the
                  future. Highly advanced technologies are used to check the
                  performance of your software to ensure a future-ready
                  application.
                </p>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon5"></i>
                </div>
                <p className="heading">Multi-platform Deployment</p>
                <p className="content">
                  Digital Crew is the best Enterprise application development
                  company that specializes in seamless integration and
                  deployment. We have a multi-platform deployment service
                  irrespective of your business size. We help you to get a
                  flexible application that will expand your growth
                  exponentially. Connect with our consultants for more details.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="section4">
          <div className="left-side">
            <img
              src={SoftwareDevImage2}
              alt="mobile app development Solution"
            ></img>
          </div>
          <div className="right-side">
            <div className="content-section">
              <p className="redHeading">Mobile App</p>
              <p className="blackHeading">Development Services</p>
              <p className="para">
                Mobile application development is the best choice to build a
                secure, scalable, and flexible digital solution to expand your
                business. Our experienced Mobile app developers verify that your
                app is engineered to make you future-forward companies. We value
                quality, time, effort, and money while creating result-driven
                apps. Digital Crew is known for helping many businesses elevate
                their business strategy, build viable solutions, design unique
                experiences, and code apps that can be value-added to users’
                lives.
              </p>
            </div>
          </div>
        </div>

        <div className="section5">
          <Row>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon1"></i>
                </div>
                <p className="heading">Mobile App Development</p>
                <p className="content">
                  Digital Crew has skilled and highly professional developers to
                  keep up with the latest technology, essential for the best
                  Mobile app development services. We offer the best Mobile app
                  development services with highly amazing features and
                  high-quality B2B and B2C apps that bring your brand exposure
                  and profit
                </p>
              </div>
            </Col>

            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon2"></i>
                </div>
                <p className="heading">UI/UX Design</p>
                <p className="content">
                  Mobile applications are popular for their memorable and
                  impressive designs. We help businesses to create an impact in
                  the market by providing the latest Mobile UI/UX design
                  services. We align your brand name with what your users expect
                  from the ecosystem and your platform. A pocket friendly Mobile
                  UI/ UX custom design service.
                </p>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon3"></i>
                </div>
                <p className="heading">Custom Mobile Application</p>
                <p className="content">
                  The startup should be unique. The unique idea needs a custom
                  Mobile application service for building scalable and featured
                  apps. Our experts create custom Mobile software for a range of
                  industries across the world. Our consultants help you to
                  choose the best Mobile platform, this way your authentic voice
                  can top the store with millions of apps.
                </p>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon4"></i>
                </div>
                <p className="heading">Software Testing</p>
                <p className="content">
                  Our Mobile development process is designed to be security and
                  performance-first. We tend to make sure that your mobile
                  application development is glitch-free and seamless. We
                  perform both the process to make your software ready for the
                  future-ready by testing manually and automatic
                </p>
              </div>
            </Col>
            <Col lg={2} md={3} sm={6} className="left-side">
              <div className="content-box">
                <div className="redbox">
                  <i className="icon AndroidIcon5"></i>
                </div>
                <p className="heading">Multi-platform Deployment</p>
                <p className="content">
                  Digital Crew is known for its multi-platform deployment
                  service. We are the best Mobile apps development company that
                  specializes in seamless integration and deployment.
                  Irrespective of which platform you want to be on, our expert
                  Mobile development services can help you get there.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="red-carousel-section">
          <div className="upper-div">
            <p className="big-redText">Our App Development Process</p>
            <p className="para">
              Digital Crew is the best app development company in India. We are
              famous for building an app from
              <br />
              elevation your development strategy, designing feature rich
              experiences by adding creativity
              <br />
              to make an unmatched valuable experience for the user end with our
              custom services.
            </p>
            <p className="para">
              We understand our client’s need and their business requirements.
              <br />
              We create design and develop custom Mobile apps for all domains.
            </p>
          </div>
          <div className="lower-div">
            <Slider {...settings} className="slider-styles">
              {sliderItemsArray.map((item) => {
                return (
                  <>
                    <div className="item-box" id={item.id}>
                      <div className="whitebox">
                        <img
                          src={item.icon}
                          alt="mobile application development"
                        ></img>
                      </div>
                      <p>{item.name}</p>
                    </div>
                  </>
                );
              })}
              <div className=""></div>
            </Slider>
          </div>
        </div>

        <div className="contact-section">
          <p className="midtitle">
            Connect to our experts for business decisions
          </p>
          <p className="midsmalltext">
            Share your ideas and planning to understand service needs
          </p>
          <div className="contact-box">
            <Row>
              <Col lg={8}>
                <form
                  encType="multipart/form-data"
                  onSubmit={submitFormHandler}
                >
                  <div className="contact-form">
                    <input
                      className="contact-input"
                      type={"text"}
                      placeholder="Full Name"
                      name={"name"}
                      onChange={handleChange}
                      value={contactFormFields.name}
                    />
                    <Row>
                      <Col lg={6}>
                        <input
                          className="contact-input contact-number"
                          type={"text"}
                          placeholder="Contact Number"
                          name={"phone"}
                          onChange={handleChange}
                          value={contactFormFields.phone}
                        />
                      </Col>
                      <Col lg={6}>
                        {" "}
                        <input
                          className="contact-input"
                          type={"text"}
                          placeholder="Email Address"
                          name={"email"}
                          onChange={handleChange}
                          value={contactFormFields.email}
                        />
                      </Col>
                    </Row>
                    <textarea
                      className="textarea"
                      placeholder="Project Description"
                      name={"projectdescription"}
                      onChange={handleChange}
                      value={contactFormFields.projectdescription}
                    ></textarea>
                    <Row className="bottom-row">
                      {/*  <Col lg={6}>
                                            <div className='file-div'>
                                                <input className='contact-input-file' type="file" accept=".gif,.jpg,.jpeg,.png,.doc,.docx" placeholder="" name="file" onChange={handleChange} />
                                                <Button className='btn btn-file'>+</Button>
                                                {contactFormFields.file !== null && <p>{contactFormFields.file?.name}</p>}
                                            </div>

                                            <p>Attached File Less then 25 mB</p>
                                        </Col> */}
                      <Col lg={12}>
                        <Button className="btn contact-form-btn" type="submit">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </form>
              </Col>
              <Col lg={4}>
                <div className="right-side-inner">
                  <Fade bottom>
                    <p className="bigtext">
                      Let's Get into
                      <br />
                      <span className="red-text">Business discussions </span> !!
                    </p>
                    <p className="smallText">
                      Our strength lies in the comprehensive suite of services
                      we offer to our clients.
                    </p>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <p className="midtitle">Get in touch</p>
          <p className="midsmalltext bottom">
            We’d like to hear from you and will be happy to answer your queries
          </p>
        </div>
      </div>
    </>
  );
}

export default SoftwareDev;
